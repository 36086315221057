import React, { useState, useEffect, useCallback, useRef } from 'react';
import { VStack, Text, ScrollView, Pressable, HStack, Divider, Tooltip, Box, Button, Input, Center, Image, CloseIcon,Modal, FlatList, Avatar, View, } from 'native-base';
import * as AV from '../../AVCore/AVCore';
import {  setUser, setEntitydata } from '../../Redux/Actions/userActions';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { AddIcon, SearchIcon, CrossIcon, ProfileAvathar, GroupChatIcon } from '../../assets/icons/icon-assets';
import AVFeeds from '../Feeds/AVFeeds';
import { Loc } from '../../App/Loc';
import AvatarWhite from '../../assets/icons/avatar-whitebg.svg';
import Loader from '../../components/common/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageLoader } from 'components/loaders';
import AVCancel from 'components/common/AVCancel';
import RenderUserInfo from 'components/ui/RenderUserInfo';
import ShowTeams from './ShowTeams';
import ShowEvents from './ShowEvents';
import AVBody from '../../components/common/AVBody';
import ProfilePic from '../../components/common/ProfilePicture';
import { Link } from '../../AVCore/Link';
import helping from '../../assets/images/helping.png';
import Createalbumpopup from '../Home/Createalbumpopup';
import useConvoChat from '../../Hooks/useConvoChat';
import AVInputtext from '../../components/AVInputtext';
import { setSearch } from '../../Redux/Actions/userActions';
import { setConvo } from '../../Redux/Actions/convoActions';
import inform from '../../assets/images/inform.png';
import suspend from '../../assets/images/unsuspend.png';
import TabHeader from '../../components/common/TabHeader';
import AVCreateEventModal from '../../components/common/AVCreateEventModal';
import _ from 'lodash';
import ProfileHeader from '../../components/common/ProfileHeader';
import AVConfirmationModal from '../../components/common/AVConfirmationModal';
import { setFeeds } from '../../Redux/Actions/feedsAction';
import CustomToast from '../../Hooks/useCustomToast';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
	FacebookShareButton,
	FacebookIcon,
	FacebookMessengerShareButton,
	FacebookMessengerIcon,
	EmailShareButton,
	EmailIcon,
	WhatsappShareButton,
	WhatsappIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	PinterestShareButton,
	PinterestIcon,
	TelegramShareButton,
	TelegramIcon,
	RedditShareButton,
	RedditIcon,
	TumblrShareButton,
	TumblrIcon,
} from "react-share";


const Profile = (props : any) => {
    const params = useParams();
    const navigate = useNavigate();
    const location : any = useLocation();
    const userID = params?.userId;
    const region = params?.region;
    const dispatch = useDispatch();
    const imageInputRef = React.useRef<HTMLInputElement>(null);
    const user = useSelector((state : RootStateOrAny) => state?.user?.user[ 0 ]);
    const [ userInfo, setUserInfo ] = useState<any>([]);
    const [ IsData, setIsData ] = useState<any>([]);
    const [ isOwner, setOwner ] = useState(false);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const data = location?.state?.user;
    const dimensions = useWindowDimension();
    const [ iswards, setiswards ] = React.useState(userInfo?.wards?.length > 0 ? true : false);
    const [ searchInput, setSearchInput ] = React.useState( '' );
    const [ NoResult, setIsNoResult ] = React.useState(false)
    const [ GuardianFilteredResults, setGuardianFilteredResults ] = React.useState([]);
    const [ guardianLocalFilteredResults, setGuardianLocalFilteredResults ] = React.useState<any>([]);
    const [ albumlist, setalbumlist ] = React.useState([]);
    const [ filesList, setfilesList ] = React.useState([]);
    const [ isDocumentPage, setIsDocumentPage ] = useState(false);
    const [ isPhotoPage, setIsPhotoPage ] = useState(false);
    const [ showAddEventsModal, setShowAddEventsModal ] = useState(false);
    const [ repeatWeek, setRepeatWeek ] = useState<any>();
    const [ error, setError ] = useState('');
    const [ filteredResults, setfilteredResults ] = useState([]);
    const [ isModalLoader, setModalLoader ] = useState(false);
    const [ locationaddress, setlocationaddress ] = useState<any>([]);
    const [ showtemplist, setshowtemplist ] = React.useState([]);
    const localUserSearch = useSelector((state : RootStateOrAny) => state.user.userSearch);
    const [ editProfile, setEditProfile ] = useState(false);
    const [ ispageLoading, setispageLoading ] = React.useState(false);
    const [ typeevent, settypeevent ] = useState('Social');
    const [ isFeedCount, setisFeedCount ] = useState<any>(0);
    const [ profile, setProfile ] = useState({
        background: null,
        pic: null,
        thumb: null,
        gallery: null,
        previousPic: null,
        text: null,
    });
    const [ isFilesView, setisFilesView ] = useState();
    const [ isConfirmDelete, setisConfirmDelete ] = useState(false);
    const [ Confirmationtext, setConfirmationtext ] = React.useState("Are you sure ?");
    const [ selectType, setselectType ] = React.useState<any>();
    const [ itemSelect, setItemSelect ] = React.useState<any>('');
    const [ isLoader, setLoader ] = useState(false);
    const [ isPractice, setIsPractice ] = useState(false);
    const [ isActives, setIsActives ] = useState(false);
    const [ activityType, setActivityType ] = useState(null);
    const [ isGame, setIsGame ] = useState(false);
    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ isSuspended, setIsSuspended ] = useState(false);
    const [ isMyPage, setIsMyPage ] = useState(true);
    const [ isEventPage, setIsEventPage ] = useState(false);
    const [ isTeamsPage, setIsTeamsPage ] = useState(false);
    const [ isMembersPage, setisMembersPage ] = useState(false);
    const [ isMember, setIsMember ] = useState(false);
    const [ isDependantspage, setisDependantspage ] = useState(false);
    const [ isGuardianspage, setisGuardianspage ] = useState(false);
    const [ staffName, setStaffName ] = useState('');
    const [ staffEmailAddress, setStaffEmailAddress ] = useState('');
    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const [ staffPhone, setStaffPhone ] = useState('');
    const [ showAddProfileModal, setShowAddProfileModal ] = useState(false);
    const [ showAddProfileModalGuardian, setshowAddProfileModalGuardian ] = useState(false);
    const [ checkvalue, setcheckvalue ] = React.useState(true);
    const [ isGuardian, setisGuardian ] = React.useState(false);
    const [ checkvaluedata, setcheckvaluedata ] = React.useState<any>('');
    const [ showSearch, setShowSearch ] = React.useState(false);
    const [ showFileuploadModal, setshowFileuploadModal ] = useState(false);
    const [ contactList, setcontactList ] = React.useState<any>();
    const [ newname, setnewname ] = React.useState<any>('');
    const [ newmail, setnewmail ] = React.useState<any>();
    const [ isDropDown, setIsDropDown ] = useState<boolean>(false);
    const rightRef : any = useRef(null);
    const [ isFollowing, setIsFollowing ] = React.useState(false);
    const [ requestSent, setRequestSent ] = React.useState(false);
    const [ isFavourite, setIsFavourite ] = React.useState(false);
    const [ isBlock, setIsBlock ] = useState(false);
    const [ showEditPopup, setShowEditPopup ] = useState(false);
    const userFeeds = useSelector((state : RootStateOrAny) => state?.feeds.feeds[ 0 ]);
    const [ memberList, setmemberList ] = React.useState([]);
    const [ DependsList, setDependsList ] = React.useState([]);
    const [ GuardianList, setGuardianList ] = React.useState([]);
    const [ isValid, setIsValid ] = useState(true);
    const [ isEditPlayerDetails, setIsEditPlayerDetails ] = useState(false);
    const [ menu, setmenu ] = React.useState<any>('');
    const isMobile = dimensions?.width <= 600 || false;
    const isLMobile = dimensions?.width <= 800 || false;
    const [ fixedWidth, setFixedWidth ] = useState<any>();
    const [ datafield, setIsDataField ] = useState<any>([]);
    const [ showFields, setShowFields ] = useState(false);
    const [ updateField, setIsUpdateField ] = useState(false);
    const [ siteField, setSiteField ] = useState<any>();
    const [ mapField, setIsMapField ] = useState();
    const [ isFieldsName, setIsFieldsName ] = useState();
    const [ fieldName, setIsFieldName ] = useState('');
    const [ showCreateNewPage, setShowCreateNewPage ] = useState<any>(false);
    const [ createType, setCreateType ] = useState<any>("");
    const entitydatalist : any = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const [ isLoad, setIsLoad ] = useState( false );
    const [ isRotate, setIsRotate ] = useState( false )
    const { handleGetGroupConvo, handleSendSingleChat } = useConvoChat();
    let userconvo : any = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const [ isEditEntireSeries, setIsEditEntireSeries ] = useState( false ); 
    const [ isOpenConvo, setIsOpenConvo ] = useState( false );
    const [ isConvoData, setIsConvoData ] = useState( '' )
    const [ isFriendsTab, setIsFriendsTab ] = useState<any>( '' );
    //User Target FK
    const targetFK : any = {
        Id: data?.id ? data?.id : userID,
        Pk: data?.pk ? data?.pk : userID?.substr(-4),
        Region: data?.region ? data?.region : region,
        Type: data?.type ? data?.type : 'Person',
    };
    //User Source FK
    const sourceFK : any = {
        Id: user?.id,
        Pk: user?.pk,
        Region: user?.region,
        Type: user?.type,
    };
    //Event Dropdwon
    const handleEvents = (type) => {
        setActivityType(type);
        if (type == 'Meeting') {
            settypeevent(type)
            setIsPractice(true);
            setRepeatWeek(true);
            setShowAddEventsModal(!showAddEventsModal);
            setIsActives(!isActives);
        } else if (type == 'Social') {
            settypeevent(type)
            setIsGame(false);
            setIsPractice(false);
            setShowAddEventsModal(!showAddEventsModal);
            setRepeatWeek(false);
        }
        else {
            setRepeatWeek(false);
            setShowAddEventsModal(!showAddEventsModal);
            setIsActives(!isActives);
        }
    };

    const HandleUnFollowUser = (item) => {
        setisConfirmDelete(true)
        setConfirmationtext('Are You Sure? You Want to UnFollow');
        setItemSelect(item);
        setselectType('Unfollow')
    }

    const UnfollowUser = async () => {
        setLoader(true);
        await AV.Person.unFollow(sourceFK, itemSelect)
        if (userFeeds) {
            let userFeed = userFeeds?.filter((x : any) => x?.owner?.id !== itemSelect?.id);
            dispatch(setFeeds(userFeed));
        }
        await AV.Person.getSelf().then(x => { dispatch(setUser(x)) });
        setLoader(false);
        setisConfirmDelete(false);
    }

    const HandleRestoreAccount = () => {
        setisConfirmDelete(true)
        setConfirmationtext('Are You Sure? You Want to Restore your Account');
        setItemSelect(userInfo);
        setselectType('RestoreAccount')
    }

    const HandleGroupChat = async ( type ) =>
    {
        setIsLoad( true )

        let validation = type == "M" ? userInfo?.mConvo : userInfo?.sConvo;
        if (validation !== '' || validation !== undefined )
        {
            setIsConvoData(validation)
        }
        if ( validation == '' || validation == undefined )
        {
            const chatfk : any = {
                id: data?.id ? data?.id : userID,
                Pk: data?.pk ? data?.pk : userID?.substr( -4 ),
                Region: data?.region ? data?.region : region,
                Type: data?.type ? data?.type : 'Person',
            };

            let Response : any = await AV.AVEntity.CreateGroupConvo( chatfk, type );
            console.log( "Response", Response )
            const fk = {
                id: type == "S" ? Response.sConvo : type == "M" ? Response?.mConvo : '',
                pk: type == "S" ? Response.sConvo : type == "M" ? Response?.mConvo : '',
                region: userInfo?.region,
                type: "GroupConvo",
            }
            const feedParam : AV.IFeedParam = {
                feed: fk,
                startDate: new Date( 0 ), forward: true
            };
            await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response !== null )
                {
                    const existingconvo = userconvo?.length > 0 && userconvo.find( ( x : any ) => x?.id === response?.id ) ? true : false;
                    if ( !existingconvo )
                    {
                        if ( userconvo?.length > 0 )
                        {
                            userconvo?.unshift( response )
                        }
                        else
                        {
                            userconvo = [];
                            userconvo?.unshift( response )
                        }
                    }
                    dispatch( setConvo( userconvo ) );
                    await handleGetGroupConvo( response )
                }
            } );
        }
        setIsOpenConvo( true )
        setIsLoad( false )
    }


    //Render Guardians
    const renderGuardianItem = (item, index) => (
        <Box
            key={ index }
            bgColor="#fff"
            borderWidth="1px"
            borderColor="AVColor.hovercolor2"
            minWidth={ 130 }
            minHeight={ 175 }
            borderRadius={ 5 }
            m={ 2 }
            py={ 3 }
            shadow={ 1 }
        >
            <Pressable
                padding={ 1.5 }
                rounded="full"
                position="absolute"
                right={ -10 }
                top={ -10 }
                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                backgroundColor="AVColor.buttonBg"
                onPress={ () => handleRemove(isGuardianspage ? 'Guardians' : 'Dependants', item) }
            >
                <CloseIcon size="12px" color="AVColor.secondaryText" />
            </Pressable>
            <VStack alignItems="center" padding={ 2 }>
                <Pressable
                    _hover={ { bgColor: 'gray.100' } }
                    p={ 2 }
                    borderRadius={ 5 }
                    minWidth={ 120 }
                    onPress={ () => {
                        navigate(`/profile/${ item?.id }/${ item?.region }`, {
                            state: {
                                user: {
                                    id: item?.id,
                                    pk: item?.pk,
                                    region: item?.region,
                                    type: item?.type,
                                    name: item?.title,
                                },
                            },
                        });
                    } }
                >
                    <Box
                        bgColor="#FF6600"
                        borderRadius="full"
                        width="60px"
                        height="60px"
                        alignSelf="center"
                    >
                        <Image
                            source={ { uri: item?.thumb ? AV.Media.getThumb(item) : AvatarWhite } }
                            borderRadius="full"
                            width="100%"
                            height="100%"
                        />
                    </Box>
                    <Tooltip label={ item.title } openDelay={ 500 }>
                        <Pressable
                            onPress={ () => {
                                navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                    state: {
                                        user: {
                                            id: item?.id,
                                            pk: item?.pk,
                                            region: item?.region,
                                            type: item?.type,
                                            name: item?.title,
                                        },
                                    },
                                });
                            } }
                        >
                            <Text
                                numberOfLines={ 2 }
                                maxWidth="100px"
                                marginTop={ 3 }
                                textAlign="center"
                                fontWeight="500"
                            >
                                { item.title }
                            </Text>
                        </Pressable>
                    </Tooltip>
                </Pressable>
            </VStack>
        </Box>
    );

	const handleupdatelocationfield = e => {
		setIsMapField(e.target.value.name);
		setIsFieldsName(e.target.value);
	};
	//RenderDepants
	const renderGuardianItems = (item, index) => (
		<Box
			key={index}
			bgColor="#fff"
			borderWidth="1px"
			borderColor="AVColor.hovercolor2"
			minWidth={130}
			minHeight={175}
			borderRadius={5}
			marginTop={"10px"}
			marginX={3}
			marginBottom={3}
			py={3}
			shadow={1}>
			<Pressable
				padding={1.5}
				rounded="full"
				position="absolute"
				right={-10}
				top={-10}
				_hover={{ backgroundColor: "AVColor.hovercolor2" }}
				backgroundColor="AVColor.buttonBg"
				onPress={() => handleRemove(isGuardianspage ? "Guardians" : "Dependants", item)}>
				<CloseIcon size="12px" color="AVColor.secondaryText" />
			</Pressable>
			<VStack alignItems="center" padding={2}>
				<Pressable
					_hover={{ bgColor: "gray.100" }}
					p={2}
					borderRadius={5}
					minWidth={120}
					onPress={() => {
						navigate(`/profile/${item?.id}/${item?.region}`, {
							state: {
								user: {
									id: item?.id,
									pk: item?.pk,
									region: item?.region,
									type: item?.type,
									name: item?.title,
								},
							},
						});
					}}>
					<Box bgColor="#FF6600" borderRadius="full" width="60px" height="60px" alignSelf="center">
						<Image
							source={{ uri: item?.thumb ? AV.Media.getThumb(item) : AvatarWhite }}
							borderRadius="full"
							width="100%"
							height="100%"
						/>
					</Box>
					<Tooltip label={item.title} openDelay={500}>
						<Pressable
							onPress={() => {
								navigate(`/profile/${item?.id}/${item?.region}`, {
									state: {
										user: {
											id: item?.id,
											pk: item?.pk,
											region: item?.region,
											type: item?.type,
											name: item?.title,
										},
									},
								});
							}}>
							<Text numberOfLines={2} maxWidth="100px" marginTop={3} textAlign="center" fontWeight="500">
								{item.title}
							</Text>
						</Pressable>
					</Tooltip>
				</Pressable>
			</VStack>
		</Box>
	);

	const handleAddTeam = async target => {
		setSearchInput(target?.title);
		setStaffName(target?.title);
		setcheckvalue(false);
		setcheckvaluedata(target);
	};
	//Home Click
	const handlePageClick = () => {
		setIsMyPage(true);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setisMembersPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setisDependantspage(false);
		setisGuardianspage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "HomePage" );
	};
	//Event Click
	const handleEventClick = () => {
		setIsMyPage(false);
		setIsEventPage(true);
		setIsTeamsPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setisMembersPage(false);
		setisDependantspage(false);
		setisGuardianspage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "EventPage" );
	};
	//Friends Click
	const handleTeamsClick = () => {
		setIsMyPage(false);
		setIsEventPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setIsTeamsPage(true);
		setisMembersPage(false);
		setisDependantspage(false);
		setisGuardianspage(false);
        setIsDropDown( false );
        localStorage.setItem( 'activeTab', "OrganizationPage" );
	};
	//Member Click
	const handleMemberClick = () => {
        setmemberList( user?.id === userID && !userInfo?.members ? user?.members : userInfo?.members );
		setIsMyPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setisGuardianspage(false);
		setisMembersPage(true);
		setisDependantspage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "FriendsPage" );
	};
	//Depandant Click
	const handleDependantsClick = () => {
        setDependsList(user?.id === userID && !userInfo?.wards ? user?.wards : userInfo?.wards );
		setisDependantspage(true);
		setisGuardianspage(false);
		setIsMyPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setisMembersPage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "DepandantPage" );
	};
	//Guardian Click
	const handleGuadrianClick = () => {
        setGuardianList(user?.id === userID && !userInfo?.guardians ? user?.guardians : userInfo?.guardians );
		setisGuardianspage(true);
		setisDependantspage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setIsMyPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setisMembersPage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "GuardianPage" );
	};
	//Album Click
	const handleAlbumClick = () => {
		setIsPhotoPage(true);
		setIsDocumentPage(false);
		setisGuardianspage(false);
		setisDependantspage(false);
		setIsMyPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setisMembersPage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "AlbumPage");
	};
	//File Click
	const handleFilesClick = () => {
		setIsPhotoPage(false);
		setIsDocumentPage(true);
		setisGuardianspage(false);
		setisDependantspage(false);
		setIsMyPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setisMembersPage(false);
        setIsDropDown( false );
        if ( user?.id === userID )
        localStorage.setItem( 'activeTab', "FilePage" );
	};

	const handleProfileType = async type => {
		setshowtemplist([]);
		setStaffName("");
		setcheckvalue(true);
		setStaffEmailAddress("");
		setStaffPhone("");
		setError("");
		setfilteredResults([]);
		if (type === "Member") {
			setIsMember(true);
			setShowAddProfileModal(true);
		} else if (type === "Dependants") {
			setshowAddProfileModalGuardian(true);
		} else {
			setIsMember(false);
			setisGuardianspage(true);
			setisGuardian(true);
			setShowAddProfileModal(true);
		}
	};

	//Remove Depandant
	const HandleRemoveDepantdant = item => {
		setisConfirmDelete(true);
		setConfirmationtext("Are You Sure? You Want to Delete Your depandants");
		setItemSelect(item);
		setselectType("DeleteDepandant");
	};
	const handleRestore = async () => {
		setLoader(true);

		if (userInfo?.status === "Deleted") {
			await AV.AVEntity.unDelete(userInfo);
			if (userInfo) {
				userInfo.status = ""; // Check if userInfo exists before modifying status
				dispatch(setUser(userInfo));
			}
		} else {
			await AV.AVEntity.unSuspend(userInfo);
		}
		setLoader(false);
		setisConfirmDelete(false);
		navigate(-1);
	};
	const HandleBlockUser = item => {
		setisConfirmDelete(true);
		setConfirmationtext("Are You Sure? You Want to Block");
		setItemSelect("block");
		setselectType("BlockUser");
	};
	const HandleUnBlockUser = item => {
		setisConfirmDelete(true);
		setConfirmationtext("Are You Sure? You Want to UnBlock");
		setItemSelect("unblock");
		setselectType("BlockUser");
	};
	const RemoveDepandants = async () => {
		setLoader(true);
		let val: any = await AV.Person.removeDependant(itemSelect);
		if (val) {
			setDependsList(val?.wards);
			dispatch(setUser(val));
			let index = entitydatalist?.findIndex(x => x?.id === val?.id);
			if (index != -1) {
				entitydatalist[index] = val;
				dispatch(setEntitydata(entitydatalist));
			}
			setUserInfo(val);
			setIsData(val);
		}
		setLoader(false);
		setisConfirmDelete(false);
	};

	//Remove friends
	const HandleRemoveFriends = item => {
		setisConfirmDelete(true);
		setConfirmationtext("Are You Sure? You Want to Delete Your Friends");
		setItemSelect(item);
		setselectType("DeleteFriends");
	};

    const RemoveFriends = async () => {
        setLoader(true)
        let removeMember : any = await AV.OrgBase.removeMember(AV.AVEntity.getFk(userInfo), {
            id: itemSelect.id,
            pk: itemSelect.pk,
            type: 'Person',
        });
        if (removeMember !== null) {

            setUserInfo(removeMember);
            let index = entitydatalist?.findIndex(x => x?.id === removeMember?.id);
            if (index != -1) {
                entitydatalist[ index ] = removeMember
                dispatch(setEntitydata(entitydatalist));
            }
            setIsData(removeMember);
            setmemberList(removeMember?.members);
        }
        setLoader(false)
        setisConfirmDelete(false)
    }

    const handleProfile = async (type) => {
        setLoader(true)
        if ( itemSelect === 'block' )
        {
            await AV.Person.block( sourceFK, targetFK )
            let userFeed = userFeeds?.filter( ( x : any ) =>
            {
                return x?.owner?.id !== targetFK?.Id;
            } );
            userFeed = userFeed?.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            userFeed = _.uniqBy( userFeed, 'id' );
            dispatch( setFeeds( userFeed ) );
            navigate(-1)
        }
        else
        {
           await AV.Person.unBlock( sourceFK, targetFK )
            const feedParam = {
                feed: { id: targetFK?.Id, pk: targetFK?.Id, type: 'Feed' },
                startDate: new Date( 0 ),
                forward: true,
                checkArchive: false,
            };
            let feed = await AV.Feed.getFeed( feedParam );
            let PostList = await AV.Feed.getPostList( feed );
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( feed ), PostList );
            for ( let i = 0; i < Post?.length; i++ )
            {
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                    {
                        Post[ i ].children = cmdPost;
                    }
                }
            }
            let userFeed = [ ...Post, ...userFeeds ]
            dispatch( setFeeds( userFeed ) );
        }
        itemSelect === 'block' ? setIsBlock( true ) : setIsBlock( false );
        await AV.Person.getSelf(true).then((x) => {
            dispatch(setUser(x));
        });
        setLoader(false)
        setisConfirmDelete(false)
    };

    //Remove Guradina
    const HandleRemoveGuardians = (item) => {
        setisConfirmDelete(true)
        setConfirmationtext('Are You Sure? You Want to Remove Your Guardians');
        setItemSelect(item);
        setselectType('Deleteguardian')
    }

    const RemoveGuardinas = async () => {
        setLoader(true)
        itemSelect.linkType = 'RemoveGuardian';
        let result : any = await AV.Person.removeGuardian(itemSelect);
        if (result) {
            dispatch(setUser(result));
            let index = entitydatalist?.findIndex(x => x?.id === result?.id);
            if (index != -1) {
                entitydatalist[ index ] = result
                dispatch(setEntitydata(entitydatalist));
            }
            setUserInfo(result);
            setIsData(result);
            setGuardianList(result?.guardians);
        }
        setLoader(false)
        setisConfirmDelete(false)
    }
    //Search List of depandants
    const RenderalertTab : any = useCallback((GuardianFilteredResults, index) => {
        let item = GuardianFilteredResults.item;
        let uri = Link.getTummb(item);
        let position = '';
        if (!uri) {
            position = 'absolute';
        }
        return (
            <ScrollView
                key={ index }
                showsHorizontalScrollIndicator={ false }
                maxHeight={ dimensions.height - 150 }
            >
                <Pressable
                    key={ item.id }
                    padding={ '10px' }
                    _hover={ { bgColor: 'AVColor.secondary' } }
                    onPress={ () => {
                        handleAddTeam(item);
                        {
                            setShowSearch(false);
                        }
                        {
                            setSearchInput('');
                        }
                    } }
                >
                    <Box>
                        <HStack>
                            <Box h={ '45px' } w={ '45px' } borderRadius={ 'full' } overflow={ 'hidden' }>
                                <Avatar
                                    bg='white'
                                    source={ { uri } }
                                    size='40px'
                                    style={ { width: '100%', height: '100%', backgroundColor: '#71717a' } }
                                >
                                    <Image source={ AvatarWhite } alt='Actavivo' size='35px' />
                                </Avatar>
                            </Box>
                            <VStack>
                                <Text
                                    fontWeight='bold'
                                    numberOfLines={ 1 }
                                    text-overflow={ 'ellipsis' }
                                    width={ '175px' }
                                    paddingLeft={ '10px' }
                                >
                                    { item.title }
                                </Text>
                                {/*  <Text fontSize={ '12px' } paddingLeft={ '10px' } >{ item.blurb }</Text>*/ }
                            </VStack>
                        </HStack>
                    </Box>
                </Pressable>
            </ScrollView>
        );
    },
        [ GuardianFilteredResults ]
    );
    //Menu
    const profileMenu = [
        {
            id: 1,
            name: Loc.currentLang.menu.home,
            flag: isMyPage, fn: handlePageClick,
        },
        ...( ( userInfo?.ownedEvents?.length > 0 || isOwner) ? [ {
            id: 2, name: Loc.currentLang.menu.events,
            flag: isEventPage, fn: handleEventClick
        } ] : []),
        ...( ( isOwner || userInfo?.members?.length > 0 ) ? [ { id: 3, name: Loc.currentLang.menu.friends, flag: isMembersPage, fn: handleMemberClick } ] : [] ),
        ...( !isOwner && userInfo?.orgs?.length > 0 ? [ { id:4, name: Loc.currentLang.menu.organization, flag: isTeamsPage, fn: handleTeamsClick } ] : [] ),
        ...( ( userInfo?.albums?.length > 0 || isOwner) ? [ {
            id:5, name: Loc.currentLang.menu.albums,
            flag: isPhotoPage, fn: handleAlbumClick
        } ] : []),
        ...( isFilesView ? [ { id:6, name: Loc.currentLang.menu.files, flag: isDocumentPage, fn: handleFilesClick } ] : [] ),
        ...( ( isOwner || user?.guardians?.length > 0 ) ? [ {
            id:7, name: Loc.currentLang.menu.guardians,
            flag: isGuardianspage, fn: handleGuadrianClick
        } ] : [] ),
        ...( ( isOwner || user?.wards?.length > 0) ? [ {
            id:8,
            name: "Dependents",
            flag: isDependantspage, fn: handleDependantsClick,
        } ] : [])


    ];

	const MoreMenuNotOwner = [];

	const profileHomeMenu: any = [];

    if (isFeedCount && isFeedCount > 0) {
        profileHomeMenu.push({
            id: 1,
            name: Loc.currentLang.menu.home,
            flag: isMyPage, fn: handlePageClick,
        });
    }

    React.useLayoutEffect(() => {
        if (rightRef.current) {
            setFixedWidth(rightRef.current.offsetWidth - 2);
        }
    }, [ [ dimensions.width ] ]);

    const handleStaffName = (item) => {
        setStaffName(item.title);
        setcheckvaluedata(item);
        setcheckvalue(false);
        setfilteredResults([]);
    };

    const handleStaffEmailAddress = (e) => {
        e.preventDefault();
        setStaffEmailAddress(e.target.value);
        if (emailRegex.test(staffEmailAddress)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const handleStaffPhone = (e) => {
        e.preventDefault();
        setStaffPhone(e.target.value);
        if (phoneRegex.test(e.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const searchStaffItems = async (e) => {
        setcheckvalue( true );
        setIsNoResult( false );
        let localsearch = await AV.Person.localSearch(e);
        if (isGuardianspage) {
            localsearch = localsearch?.filter(
                (item1) => !user?.wards?.some((item2) => item2?.id === item1?.id)
            );
        } else {
            localsearch = localsearch?.filter(
                (item1) => !user?.guardians?.some((item2) => item2?.id === item1?.id)
            );
        }
        localsearch = localsearch?.filter(
            ( item1 ) => !user?.blocked?.some( ( item2 ) => item2?.id === item1?.id )
        );

        // Ignore the user's own ID
        localsearch = localsearch.filter((item) => item.id !== user.id);

        setGuardianLocalFilteredResults(_.uniqBy(localsearch?.filter((x) => x?.type === 'Person'), 'id'));
        setShowSearch(true);
        setStaffName(e);
        if (e?.trim()?.length === 0) setShowSearch(false);
    };

    const handleAddProfile = async (type) => {
        setModalLoader(true);
        setShowSearch(false);
        if (type == 'Member' && emailRegex.test(staffEmailAddress) && checkvalue) {
            let addMember : any = await AV.OrgBase.addMember(AV.AVEntity.getFk(userInfo), {
                name: staffName,
                email: staffEmailAddress,
                phone: staffPhone,
            });
            if (addMember !== null) {
                dispatch(setUser(addMember));
                let index = entitydatalist?.findIndex(x => x?.id === addMember?.id);
                if (index != -1) {
                    entitydatalist[ index ] = addMember
                    dispatch(setEntitydata(entitydatalist));
                }
                setUserInfo(addMember);
                setIsData(addMember);
                setmemberList(addMember?.members);
                let tempstaff : any = showtemplist;
                tempstaff.push(addMember?.members[ addMember?.members?.length - 1 ]);
                setshowtemplist(tempstaff);
            }
            setStaffName('');
            setStaffEmailAddress('');
            setStaffPhone('');
        } else if (type == 'Member' && !checkvalue) {
            let addMember : any = await AV.OrgBase.addMember(AV.AVEntity.getFk(userInfo), {
                name: staffName,
                email: staffEmailAddress,
                id: checkvaluedata.id,
                pk: checkvaluedata.pk,
                type: checkvaluedata.type,
                region: checkvaluedata.region,
                phone: staffPhone,
            });
            if (addMember !== null) {
                dispatch(setUser(addMember));
                let index = entitydatalist?.findIndex(x => x?.id === addMember?.id);
                if (index != -1) {
                    entitydatalist[ index ] = addMember
                    dispatch(setEntitydata(entitydatalist));
                }
                setUserInfo(addMember);
                setIsData(addMember);
                setmemberList(addMember?.members);
                let tempstaff : any = showtemplist;
                tempstaff.push(addMember?.members[ addMember?.members?.length - 1 ]);
                setshowtemplist(tempstaff);
            }
            setStaffName('');
            setcheckvalue(true);
        } else if (type == 'Guardian' && emailRegex.test(staffEmailAddress) && checkvalue) {
            if (userInfo?.email == staffEmailAddress) {
                let clientError = { message: "Invalid Email" }
                CustomToast({ clientError });
                setcheckvaluedata('');
                setcheckvalue(true);
                setModalLoader(false);
                return;
            }

            let addGuardian : any = await AV.Person.addGuardian({
                name: staffName,
                email: staffEmailAddress,
                phone: staffPhone,
            });
            if (addGuardian !== null) {
                dispatch(setUser(addGuardian));
                let index = entitydatalist?.findIndex( x => x?.id === addGuardian?.id );
                if ( index != -1 )
                {
                    entitydatalist[ index ] = addGuardian
                    dispatch( setEntitydata( entitydatalist ) );
                }
                setUserInfo(addGuardian);
                setIsData(addGuardian)
                setGuardianList(addGuardian?.guardians);
                let tempstaff : any = showtemplist;
                tempstaff.push(addGuardian?.guardians[ addGuardian?.guardians?.length - 1 ]);
                setshowtemplist(tempstaff);
            }
            setStaffName('');
            setStaffEmailAddress('');
            setStaffPhone('');
        } else if (type == 'Guardian' && !checkvalue) {
            if (userInfo?.email == staffEmailAddress) {
                let clientError = { message: "Invalid Email" }
                setcheckvaluedata('');
                setcheckvalue(true);
                setModalLoader(false);
                CustomToast({ clientError });
                return;
            }
            let addGuardian : any = await AV.Person.addGuardian({
                name: staffName,
                email: staffEmailAddress,
                id: checkvaluedata.id,
                pk: checkvaluedata.pk,
                type: checkvaluedata.type,
                region: checkvaluedata.region,
                phone: staffPhone,
            });
            if (addGuardian !== null) {
                dispatch( setUser( addGuardian ) );
                let index = entitydatalist?.findIndex( x => x?.id === addGuardian?.id );
                if ( index != -1 )
                {
                    entitydatalist[ index ] = addGuardian
                    dispatch( setEntitydata( entitydatalist ) );
                }
                setUserInfo(addGuardian);
                setIsData(addGuardian);
                setGuardianList(addGuardian?.guardians);
                let tempstaff : any = showtemplist;
                tempstaff.push(addGuardian?.guardians[ addGuardian?.guardians?.length - 1 ]);
                setshowtemplist(tempstaff);
            }
            setStaffName('');
            setcheckvalue(true);
        } else if (type == 'Dependants') {
            checkvaluedata.linkType = 'Dependant';
            let result : any = await AV.Person.makeRequest(sourceFK, checkvaluedata);
            if (result) {
                setUserInfo( result );
                let index = entitydatalist?.findIndex( x => x?.id === result?.id );
                if ( index != -1 )
                {
                    entitydatalist[ index ] = result
                    dispatch( setEntitydata( entitydatalist ) );
                }
                setIsData(result);
                setmemberList(result?.wards);
            }
        }
        setcheckvaluedata('');
        setcheckvalue(true);
        setModalLoader(false);
    };

    const clearValue = () => {
        setSearchInput('');
        setShowSearch(false);
        setcheckvalue(true);
        setcheckvaluedata('');
        setGuardianFilteredResults([]);
        setGuardianLocalFilteredResults([]);
    };

    const handleRemove = async (type, item) => {
        if (type === 'Member') {
            setIsLoading(true);
            let removeMember : any = await AV.OrgBase.removeMember(AV.AVEntity.getFk(userInfo), {
                id: item.id,
                pk: item.pk,
                type: 'Person',
            });
            if (removeMember !== null) {
                setUserInfo(removeMember);
                setIsData(removeMember);
                setmemberList(removeMember?.members);
            }
            setIsLoading(false);
        } else if (type === 'Dependants') {
            let val : any = await AV.Person.removeDependant(item);
            if (val) {
                setDependsList(val?.wards);
                dispatch(setUser(val));
                setUserInfo(val);
                setIsData(val);
            }
        } else if (type === 'Guardians') {
            item.linkType = 'RemoveGuardian';
            let result : any = await AV.Person.removeGuardian(item);
            if (result) {
                dispatch(setUser(result));
                setUserInfo(result);
                setIsData(result);
                setGuardianList(result?.guardians);
            }
        }
        setIsLoading(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(doc);
        // Check file extensions
        const allowedExtensions = [ 'docx', 'xlsx', 'ods', 'odt', 'csv', 'txt' ];
        const validFiles = files.filter((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(fileExtension);
        });
        if (validFiles.length === 0) {
            setError(Loc.currentLang.menu.invaildFileMsg);
            return;
        }
        let val = AV.Media.getFormData(validFiles);
        let contact = await AV.AVEntity.fileContacts(val);
        if (contact?.length > 0) {
            setcontactList(contact);
            setshowFileuploadModal(true);
        }
        if (contact?.length === 0) {
            setError("Need data for Name,Email and Role");
        }
        fileInput.value = null;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const findPersonInChats = ( conversations, personToCheck ) =>
    {
        return conversations.find( convo =>
            convo?.subject === '' &&
            convo?.people?.some( person =>
                person?.id === personToCheck?.id &&
                person?.pk === personToCheck?.pk
            )
        );
    };

    const handleChat = async ( item : any ) =>
    {
        setIsFriendsTab( item );
        setIsRotate( true )
        let People : any = [];
        People?.push( item )
        let valid = await findPersonInChats( userconvo, item )
        if ( valid?.id !== undefined )
        {
            setIsConvoData( valid?.id )
            setIsRotate( false )
            setIsOpenConvo( true )
            return;
        }

        await handleSendSingleChat( People );
        setIsRotate( false )
        setIsOpenConvo( true );
        setIsFriendsTab( '' );
    }

    const handlefileUpload = async (e : any) => {
        setError('');
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(e?.target?.files);
        // Check file extensions
        const allowedExtensions = [ 'docx', 'xlsx', 'ods', 'odt', 'csv', 'txt' ];
        const validFiles = files.filter((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(fileExtension);
        });
        if (validFiles.length === 0) {
            setError(Loc.currentLang.menu.invaildFileMsg);
            return;
        }
        let val = AV.Media.getFormData(validFiles);
        let contact = await AV.AVEntity.fileContacts(val);
        if (contact?.length > 0) {
            setcontactList(contact);
            setshowFileuploadModal(true);
        }
        if (contact?.length === 0) {
            setError("Need data for Name,Email and Role");
        }
        fileInput.value = null;
    };

	const handleUpload = async e => {
		let val: any = await AV.Person.save(e);
		if (val) {
			setalbumlist(val.albums);
			dispatch(setUser(val));
		}
	};

    const searchGuardian = async (searchValue, isGuardianspage) => {
        setSearchInput(searchValue);
        if (searchValue !== '') {
            let searchdata = await AV.Person.search(searchValue, 'Person');
            let locSearch = localUserSearch;
            if (locSearch == undefined) {
                locSearch = [];
            }
            locSearch = locSearch.concat(searchdata);
            locSearch = _.uniqBy(locSearch, 'id');
            locSearch = locSearch?.filter(x => x?.type == 'Person')
            dispatch(setSearch(locSearch));
            locSearch = locSearch?.filter(
                (x) =>
                (x?.title !== undefined &&
                    x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            )
            if (isGuardianspage) {
                locSearch = locSearch?.filter(
                    (item1) => !user?.wards?.some((item2) => item2?.id === item1?.id)
                );
                locSearch = locSearch?.filter((item1) => item1?.id !== user?.id)
            } else {
                locSearch = locSearch?.filter(
                    (item1) => !user?.guardians?.some((item2) => item2?.id === item1?.id)
                );
                locSearch = locSearch?.filter((item1) => item1?.id !== user?.id)
            }
            if ( locSearch?.length == 0 )
            {
                setIsNoResult( true )
            }
            setGuardianFilteredResults(locSearch);
            setGuardianLocalFilteredResults(locSearch);
            setShowSearch(true);
        } else {
            setSearchInput('');
            setGuardianFilteredResults([]);
            setGuardianLocalFilteredResults([]);
        }
    };

    const [ shareUrl, setshareUrl ] = React.useState(window.location.origin);

    async function fetchUrl () {

        let contact : AV.ITokenProcessRequest = {
            type: 'Add',
            entity: AV.AVEntity.getLink(user),
        };
        let val : any = await AV.AVEntity.createTokenRequest(contact);
        var URL = `${ window.location.origin }/${ val?.type }/${ val?.token }`
        setshareUrl(URL);

    }

    const SocialShare = (shareUrl) => {
        console.log(shareUrl?.shareUrl);
        const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
        const title = Loc.currentLang.post.inviteMessage;
        const body = Loc.currentLang.post.inviteMessage; // "Hey! Join me on Actavivo. Let's make Actavivo our new favorite!";
        return (
            <HStack alignItems={ 'center' } space={ 5 } width={ '100%' } justifyContent={ 'center' } flexWrap={ 'wrap' }>
                <WhatsappShareButton
                    onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    separator=':: '
                    className='Demo__some-network__share-button'
                >
                    <WhatsappIcon size={ 35 } round />
                </WhatsappShareButton>
                <FacebookShareButton
                    onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    quote={ title }
                    className='Demo__some-network__share-button'
                >
                    <FacebookIcon size={ 35 } round />
                </FacebookShareButton>

                {/*<FacebookMessengerShareButton*/}
                {/*    onClick={ fetchUrl }*/}
                {/*    url={ shareUrl.shareUrl }*/}
                {/*    appId='155052346752370'*/}
                {/*    className='Demo__some-network__share-button'*/}
                {/*>*/}
                {/*    <FacebookMessengerIcon size={ 35 } round />*/}
                {/*</FacebookMessengerShareButton>*/}

                <LinkedinShareButton url={ shareUrl.shareUrl } className='Demo__some-network__share-button' onClick={ fetchUrl }>
                    <LinkedinIcon size={ 35 } round />
                </LinkedinShareButton>

                <TwitterShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TwitterIcon size={ 35 } round />
                </TwitterShareButton>

                <EmailShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    subject={ title }
                    body={ body }
                    className='Demo__some-network__share-button'
                >
                    <EmailIcon size={ 35 } round />
                </EmailShareButton>

                <PinterestShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    media={ `https://actavivo.net/wp-content/uploads/2023/08/homebanner.png` }
                    className='Demo__some-network__share-button'
                >
                    <PinterestIcon size={ 35 } round />
                </PinterestShareButton>

                <TelegramShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TelegramIcon size={ 35 } round />
                </TelegramShareButton>

                {/*<RedditShareButton onClick={ fetchUrl }*/}
                {/*    url={ shareUrl.shareUrl }*/}
                {/*    title={ title }*/}
                {/*    windowWidth={ 660 }*/}
                {/*    windowHeight={ 460 }*/}
                {/*    className='Demo__some-network__share-button'*/}
                {/*>*/}
                {/*    <RedditIcon size={ 35 } round />*/}
                {/*</RedditShareButton>*/}

                <TumblrShareButton onClick={ fetchUrl }
                    url={ shareUrl.shareUrl }
                    title={ title }
                    className='Demo__some-network__share-button'
                >
                    <TumblrIcon size={ 35 } round />
                </TumblrShareButton>
            </HStack>
        );
    };

    const handlesearchTeams = async (e) => {
        let localsearch = await AV.Person.localSearch(e);
        let locallySearch = localUserSearch;
        if (locallySearch == undefined) {
            locallySearch = [];
        }
        locallySearch = locallySearch.concat(localsearch);
        locallySearch = _.uniqBy(locallySearch, 'id');
        locallySearch = locallySearch?.filter(x => x?.type == 'Person')
        locallySearch = locallySearch?.filter(
            (x) =>
            (x?.title !== undefined &&
                x?.title?.toLocaleLowerCase().includes(e.toLocaleLowerCase()))
        )
        if (!isGuardianspage) {
            locallySearch = locallySearch?.filter((item1) => !user?.wards?.some((item2) => item2?.id === item1?.id));
            locallySearch = locallySearch?.filter((item1) => item1?.id !== user?.id)

        } else {
            locallySearch = locallySearch?.filter((item1) => !user?.guardians?.some((item2) => item2?.id === item1?.id));
            locallySearch = locallySearch?.filter((item1) => item1?.id !== user?.id);
        }
        setGuardianLocalFilteredResults(locallySearch?.filter((x) => x?.type === 'Person'));
        setShowSearch(true);
        setSearchInput(e);
        if (e?.trim()?.length === 0) setShowSearch(false);
    };

    const handleRemoveStaff = async (item) => {
        setcontactList(contactList?.filter((x) => x !== item));
    };

    const handleUpdateStaff = async (item) => {
        if (newname?.trim()?.length > 0) menu.name = newname;
        if (newmail?.trim()?.length > 0) menu.email = newmail;
        setmenu(menu);
        contactList[ contactList.indexOf(item) ] = menu;
        setcontactList(contactList);
        setmenu('');
        setIsEditPlayerDetails(false);
        setnewname('');
        setnewmail('');
    };

    const handleAddContactList = async () => {
        setIsLoading(true);
        if (isMember) {
            let member : any = await AV.OrgBase.addMembers(AV.AVEntity.getFk(userInfo), contactList);
            if ( member )
            {
                setmemberList( member?.members );
                setUserInfo( member );
                entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === member?.id ) ) ] = member;
                dispatch( setEntitydata( entitydatalist ) );
                dispatch( setUser( member ) );
            }
        }
        else {
            for (let i = 0; i < contactList?.length; i++) {
                let addGuardian : any = await AV.Person.addGuardian(contactList[ i ])
                if (addGuardian) {
                    setGuardianList( addGuardian?.guardians );
                    setmemberList( addGuardian?.members );
                    entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === addGuardian?.id ) ) ] = addGuardian;
                    dispatch( setEntitydata( entitydatalist ) );
                    setUserInfo( addGuardian );
                    dispatch( setUser( addGuardian ) );
                }
            }
        };
        setIsLoading(false);
        setshowFileuploadModal(false);
    };

    const handleSwitchTab = async() =>
    {
        const CurrentTab = localStorage.getItem( 'activeTab' );
        switch ( CurrentTab )
        {
            case "HomePage":
                await handlePageClick();
                break;
            case "EventPage":
                await handleEventClick();
                //setIsEventPage( true );
                break;
            case "OrganizationPage":
                await handleTeamsClick();
                break;
            case "FriendsPage":
                await handleMemberClick();
                break;
            case "DepandantPage":
                await handleDependantsClick();
                break;
            case "GuardianPage":
                await handleGuadrianClick();
                break;
            case "AlbumPage":
                await handleAlbumClick();
                break;
            case "FilePage":
                await handleFilesClick();
                break;
            default :
                await handlePageClick();
                break;
        }
    }

    useEffect( () =>
    {
        if ( isEventPage )
        {
            //setIsEventPage( true );
            //handleEventClick();
        }

    }, [ isEventPage ])
    const menuRefs = useRef(null);

    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowEditPopup(false);
                    setShowSearch( false );

                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const fetchData = async () =>
        {
            if (user?.id === userID) {
                if (entitydatalist?.filter(x => x?.id === user?.id)?.length == 0) {
                    setUserInfo(user);
                    entitydatalist?.push(user)
                    dispatch(setEntitydata(entitydatalist));
                }
                else {
                    let index = entitydatalist?.findIndex(x => x?.id === user?.id);
                    setUserInfo(entitydatalist[ index ]);
                }
                setIsData(user);
                setGuardianList(user?.guardians);
                setDependsList(user?.wards);
                setalbumlist(user?.albums);
                setfilesList(user?.files);
                setProfile(user?.Profile);
                setOwner(user?.status === 'Deleted' ? false : true);
                setiswards(userInfo?.wards?.length > 0 ? true : false);
            } else {
                setispageLoading(true);
                try {
                    if (entitydatalist?.filter(x => x?.id === targetFK?.Id)?.length == 0) {
                        const result : any = await AV.Person.get(targetFK);

                        if (result) {
                            setUserInfo(result);
                            entitydatalist?.push(result)
                            dispatch(setEntitydata(entitydatalist));
                            setIsData(result);
                        }
                    }
                    else {
                      let index = entitydatalist?.findIndex(x => x?.id === userID);
                      if (index != -1) {
                        if (entitydatalist[ index ]?.albums == null || entitydatalist[ index ]?.albums == undefined) {
                          const result : any = await AV.Person.get(targetFK);
                          if (result) {
                            setUserInfo(result);
                            setUserInfo(result);
                            setIsData(result);
                            setalbumlist(result?.albums);
                            setfilesList(result?.files);
                            entitydatalist?.push(result)
                            dispatch(setEntitydata(entitydatalist));
                            setIsData(result);
                          }
                        }
                        else
                        {
                            let index = entitydatalist?.findIndex( x => x?.id === userID );
                            if ( index != -1 )
                            {
                                setUserInfo( entitydatalist[index]);
                                setIsData( entitydatalist[ index ] );
                                setDependsList( entitydatalist[index]?.wards );
                                setalbumlist( entitydatalist[index]?.albums );
                                setfilesList( entitydatalist[index]?.files );
                            }
                            const resultdata : any = await AV.Person.get( targetFK );
                            if ( resultdata !== null )
                            {


                                setUserInfo( resultdata );
                                setIsData( resultdata );
                                setDependsList( resultdata?.wards );
                                setalbumlist( resultdata?.albums );
                                setfilesList( resultdata?.files );
                            }
                            if ( resultdata == null && index != -1)
                            {
                                setispageLoading( false );
                            }
                          }
                        }
                    }

                    setispageLoading(false);
                    if (userInfo?.length > 0) {
                        AV.AVEntity.checkLinks(user, userInfo).then((x) => {
                            dispatch(setUser(x));
                        });
                        if (userInfo) {
                            setGuardianList(userInfo?.guardians);
                            setDependsList(userInfo?.wards);
                            setalbumlist(userInfo?.albums);
                            setfilesList(userInfo?.files);

                            setIsFollowing(user?.follows?.some((item) => item?.id === userInfo?.id));
                            setRequestSent(
                                user?.myRequests?.some((item) => item?.target?.id === userInfo?.id && item?.type === 'Follow')
                            );
                            setIsFavourite(user?.favs?.some((item) => item?.id === userInfo?.id));
                            let localesearchperson : any = localUserSearch;
                            let index = localesearchperson.findIndex(item => item.id === userInfo?.id);
                            if (index !== -1) {
                                localesearchperson[ index ] = AV.AVEntity.getLink(userInfo);
                            }
                            localesearchperson = _.uniqBy(localesearchperson, 'id');
                            localesearchperson = localesearchperson?.filter(x => x?.type == 'Person')
                            dispatch(setSearch(localesearchperson));
                            const owner = user?.id === userID;
                            const memberCheck = user?.members?.some((x) => x?.id === userID) ?? false;
                            setOwner(owner);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setispageLoading(false);
                }
            }
        };
        let isMounted = true;
        if (isMounted) {
            fetchData();
            if ( user?.id === userID )
            {
                handleSwitchTab();
                localStorage.removeItem( 'activeTab' );
            }
            else
            {
                handlePageClick();
            }
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [ userID ]);

    useEffect( () =>
    {
        const CurrentTab = localStorage.getItem( 'activeTab' );
        if ( CurrentTab == 'HomePage' || CurrentTab == null)
            setIsMyPage( true )
    }, [ showCreateNewPage, createType ] )

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const memberCheck = user?.members?.some((x) => x?.id === userID) ?? false;
            const guardianCheck = user?.guardians?.some((x) => x?.id === userID) ?? false;

            setisFilesView(memberCheck || isOwner);
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

    }, [ userID, isOwner ]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const following = user?.follows?.filter((item) => item?.id === userInfo?.id);
            const requests = user?.myRequests?.filter((item) => item?.target?.id === userInfo?.id && item?.type === 'Follow');
            const favourites = user?.favs;
            const Blocked = user?.blocked;

            following?.length > 0
                ? setIsFollowing(true)
                : setIsFollowing(false);
            requests?.length > 0
                ? setRequestSent(true)
                : setRequestSent(false);
            favourites?.filter((item) => item?.id === userInfo?.id).length > 0
                ? setIsFavourite(true)
                : setIsFavourite(false);
            Blocked?.filter((item) => item?.id === userInfo?.id).length > 0
                ? setIsBlock(true)
                : setIsBlock(false);
            setIsDeleted(user?.status === 'Deleted' ? false : true);
            setIsSuspended(user?.status === 'Suspended' ? false : true);
            if (user?.id === userID) {
                setGuardianList(user?.guardians);
                setDependsList(user?.wards);
            }
            else {
                setOwner(false);
            }
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [ data, userID, user, user?.follows, user?.requests, userInfo, user?.fpropsavs ]);

    const [ showEdit, setIsShowEdit ] = useState(false);

    return (
        <>
            <AVBody
                setShowCreateNewPage={ setShowCreateNewPage } setIsShowEdit={ setIsShowEdit } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ undefined } setPassEntity={ undefined } isOpenConvo={ isOpenConvo } setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } isConvoData={ isConvoData }>
                { (userInfo?.length === 0 || ispageLoading) ? (<PageLoader />) :
                    <>
                        { userInfo?.id?.trim()?.length > 0 && (
                            <VStack flex={ [ 1, 1, 1, 3 ] } >
                                <VStack
                                    height={ 'auto' }
                                    zIndex={ isDropDown ? '' : -3 }
                                    borderRadius={ 5 }
                                    backgroundColor={ 'AVColor.white' }
                                    shadow={ 1 }
                                    borderTopLeftRadius={ 10 }
                                    borderTopRightRadius={ 10 }
                                    marginBottom={ '12px' }
                                >
                                    {/*ProfileHeader*/ }
                                    <ProfileHeader Entity={ userInfo } handleUpload={ handleUpload } EntityType={ userInfo } profile={ profile } setProfile={ setProfile } setIsShowEdit={ setEditProfile } isShowEdit={ editProfile } isAdminView={ isOwner } isCreate={ undefined } setEntity={ undefined } type={ undefined } googlemap={ undefined } setIsStaffReq={ undefined } setIsChildOrgReq={ undefined } weatherURL={ undefined } weather={ undefined } Owner={ undefined } setIsImagePopup={ undefined } editAccess={ undefined } isDeleted={ isDeleted } HandleBlockUser={ HandleBlockUser } HandleUnBlockUser={ HandleUnBlockUser } HandleRestoreAccount={ HandleRestoreAccount } HandleUnFollowUser={ HandleUnFollowUser } setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } setIsEditEntireSeries={ setIsEditEntireSeries } isEditEntireSeries={ isEditEntireSeries } seriesEvent={''} />
                                    {/*EditUserProfile*/ }
                                    <Box
                                        height={ editProfile ? '400px' : '' }
                                        marginTop={ isMobile && editProfile ? '70px' : editProfile ? '50px' : isMobile && !editProfile ? '0px' : '' }

                                        backgroundColor={ editProfile ? 'white' : '' }
                                    >
                                        { userInfo?.id &&
                                            <RenderUserInfo data={ userInfo } setUserInfo={ setUserInfo } isOwner={ isOwner } userID={ userID } isMobile={ isMobile } editProfile={ editProfile } setEditProfile={ setEditProfile } setIsMyPage={ setIsMyPage } setIsEventPage={ setIsEventPage } setIsTeamsPage={ setIsTeamsPage } setIsPhotoPage={ setIsPhotoPage } setIsDocumentPage={ setIsDocumentPage } setisMembersPage={ setisMembersPage } setisDependantspage={ setisDependantspage } setisGuardianspage={ setisGuardianspage } />
                                        }
                                    </Box>
                                    {/*TabHeader*/ }
                                    { (userInfo?.status !== "NewUser" && !editProfile && user?.blocked?.filter((x) => x?.id === data?.id)?.length === 0) &&
                                        (
                                            <HStack height={ (!isOwner && !isFollowing && user?.autoFollows?.filter((x : any) => x?.id == userInfo?.id)?.length == 0 && isFeedCount == 0) ? 0 : '61px' } width={ '100%' }>
                                                <TabHeader menu={ (!isOwner && !isFollowing && user?.autoFollows?.filter((x : any) => x?.id == userInfo?.id)?.length == 0) ? profileHomeMenu : profileMenu } type='profile' MoreMenu={ isMobile ? profileMenu : !isOwner && !isFollowing ? MoreMenuNotOwner : profileMenu } isDropDown={ isDropDown } setIsDropDown={ setIsDropDown } Entity={ userInfo } />
                                            </HStack>
                                        ) }
                                </VStack>
                                {/*Feeds*/ }
                                <VStack flex={ 1 } ref={ rightRef } zIndex={ -3 }>
                                    { isMyPage && (userInfo?.length !== 0) && (userInfo?.status !== "NewUser" && userInfo?.status !== 'Deleted') && !editProfile && (user?.blocked?.filter((x) => x?.id === data?.id)?.length === 0) && (
                                        <AVFeeds
                                            page={ 'Profile' }
                                            isOwner={ isOwner }
                                            userInfo={ userInfo }
                                            id={ userID }
                                            setisFeedCount={ setisFeedCount }
                                            isFollowing={ user?.autoFollows?.filter((x : any) => x?.id == userInfo?.id)?.length > 0 ? true : isFollowing }
                                            offW={ fixedWidth }
                                            { ...props }
                                        />
                                    ) }
                                    {/*EventsTab*/ }
                                    { (isOwner || isFollowing || user?.autoFollows?.filter((x : any) => x?.id == userInfo?.id)?.length > 0) && (
                                        <>
                                            { isEventPage &&
                                                <ShowEvents
                                                    handleEvents={ handleEvents }
                                                    userData={ userInfo }
                                                    isOwner={ isOwner }
                                                />
                                            }
                                            { isTeamsPage && (
                                                <>
                                                    <ShowTeams userData={ userInfo } />
                                                </>
                                            ) }
                                            { isMembersPage && (
                                                <Box
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                { Loc.currentLang.menu.friends }
                                                            </Text>

                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                {/*<SearchIcon size='5' marginRight={ 5 } />*/ }
                                                                <HStack>
                                                                    <Tooltip
                                                                        label={ Loc.currentLang.helpText.person.friendTab }
                                                                        w={ 200 }
                                                                        openDelay={ 500 }
                                                                    >
                                                                        <Box
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            shadow={ 1 }
                                                                            display={ memberList?.length > 0 ? '' : 'none' }
                                                                            marginTop={ '3px' }
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img src={ helping } height={ '17px' } width={ '17px' } />
                                                                        </Box>
                                                                    </Tooltip>

                                                                    { isOwner && memberList?.length > 0 && (
                                                                        <Button
                                                                            p={ '9px' }
                                                                            marginRight={'10px' }
                                                                            rounded={ 'full' }
                                                                            bgColor={ isLoad ? "gray.400": 'gray.100' }
                                                                            _hover={ { bgColor: 'gray.50' } }
                                                                            shadow={ 1 }
                                                                            isLoading={ isLoad }
                                                                        onPress={ () => HandleGroupChat( 'M' ) }
                                                                    >
                                                                        {
                                                                        
                                                                                    <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } /> 
                                                                        }

                                                                        </Button> ) }
                                                                    { isOwner && (
                                                                        <Button
                                                                            p={ '9px' }
                                                                            rounded={ 'full' }
                                                                            bgColor={ 'gray.100' }
                                                                            _hover={ { bgColor: 'gray.50' } }
                                                                            shadow={ 1 }
                                                                            onPress={ () => handleProfileType('Member') }
                                                                        >
                                                                            <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                        </Button>
                                                                    ) }
                                                                    </HStack>
                                                                    
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>

													<Divider my={2} zIndex={-3} />

                                                    <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 3 } p={ 2 }>
                                                        { memberList?.map((item : any, i) => (
                                                            <Box
                                                                key={ item.id + i }
                                                                bgColor={ '#fff' }
                                                                marginTop={ '10px' }
                                                                borderWidth={ '1px' }
                                                                zIndex={-3}
                                                                borderColor={ 'AVColor.hovercolor2' }
                                                                minWidth={ 160 }
                                                                minHeight={ 190 }
                                                                borderRadius={ 5 }
                                                                marginBottom={ '5px' }
                                                                marginX={ '5px' }
                                                                shadow={ 1 }
                                                            >
                                                                { userInfo?.id !== item?.id && (
                                                                    <Pressable zIndex={999} position={ 'absolute' } top={ '10px' } left={ '10px' } onPress={ () => handleChat( item ) }>
                                                                        { ( isRotate && item?.id === isFriendsTab ?.id) ?
                                                                            <div className="loading"></div> : <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } />
                                                                        }
                                                                    </Pressable>
                                                                ) }
                                                                { isOwner && (<Pressable
                                                                    key={ item.id }
                                                                    padding={ 1.5 }
                                                                    rounded={ 'full' }
                                                                    position={ 'absolute' }
                                                                    right={ -10 }
                                                                    top={ -10 }
                                                                    zIndex={ 999 }
                                                                    _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                    backgroundColor={ 'AVColor.buttonBg' }
                                                                    onPress={ () => HandleRemoveFriends(item) }
                                                                >
                                                                    <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                                                                </Pressable>) }

                                                                <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                                                                    <Pressable
                                                                        key={ item.id }
                                                                        height={ '100%' }
                                                                        width={ '100%' }
                                                                        justifyContent={ 'center' }
                                                                        _hover={ { bgColor: '#EEEEEE' } }
                                                                        onPress={ () => {
                                                                            navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                                                                state: {
                                                                                    user: {
                                                                                        id: item?.id,
                                                                                        pk: item?.pk,
                                                                                        region: item?.region,
                                                                                        type: item?.type,
                                                                                        name: item?.title,
                                                                                    },
                                                                                },
                                                                            });
                                                                        } }
                                                                    >
                                                                        { item?.thumb && (
                                                                            <Box
                                                                                bgColor={ '#FF6600' }
                                                                                borderRadius={ [ 'full' ] }
                                                                                width={ '70px' }
                                                                                height={ '70px' }
                                                                                alignSelf={ 'center' }
                                                                                p={ 0 }
                                                                            >
                                                                                <Image
                                                                                    source={ {
                                                                                        uri: AV.Media.getThumb(item),
                                                                                    } }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    //top={ "2" }
                                                                                    width={ '100%' }
                                                                                    height={ [ '100%' ] }
                                                                                />
                                                                            </Box>
                                                                        ) }
                                                                        { !item?.thumb && (

                                                                            <Box
                                                                                alignItems={ 'center' }
                                                                            >
                                                                                <ProfileAvathar height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                            </Box>
                                                                        ) }
                                                                        <HStack alignItems='center'
                                                                            justifyContent={'center' }
                                                                        >
                                                                        <Text
                                                                            alignItems={ 'center' }
                                                                            marginTop={ 2 }
                                                                            left={ '2px' }
                                                                           
                                                                            width='120px'
                                                                            numberOfLines={ 2 }
                                                                            textAlign='center'
                                                                        >
                                                                            { item.title }
                                                                            </Text>
                                                                        </HStack>
                                                                    </Pressable>
                                                                </VStack>
                                                            </Box>
                                                        )) }
                                                    </HStack>
                                                    { (memberList?.length === 0 || memberList?.length == undefined) && (
                                                        <HStack>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    padding: '10px',
                                                                    marginTop: '-10px',
                                                                } }
                                                            />
                                                            { Loc.currentLang.helpText.person.friendTab }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            ) }
                                            { isDependantspage && (
                                                <Box
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                { "Dependents" }
                                                            </Text>
                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                {/*<SearchIcon size='5' marginRight={ 5 } />*/ }

                                                                <HStack>
                                                                    <Tooltip
                                                                        label={ Loc.currentLang.helpText.person.dependentTab }
                                                                        w={ 200 }
                                                                        openDelay={ 500 }
                                                                    >
                                                                        <Box
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            display={ DependsList?.length > 0 ? '' : 'none' }
                                                                            shadow={ 1 }
                                                                            marginTop={ '3px' }
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img src={ helping } height={ '17px' } width={ '17px' } />
                                                                        </Box>
                                                                    </Tooltip>
                                                                    { isOwner && (
                                                                        <Button
                                                                            p={ [ 1, 2 ] }
                                                                            rounded={ 'full' }
                                                                            bgColor={ 'gray.100' }
                                                                            _hover={ { bgColor: 'gray.50' } }
                                                                            shadow={ 1 }
                                                                            //disabled={ isCreate }
                                                                            onPress={ () => handleProfileType('Dependants') }
                                                                        >
                                                                            <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                        </Button>
                                                                    ) }
                                                                </HStack>
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>

                                                    <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 3 } p={ 2 }>
                                                        { DependsList?.map((item : any, i) => (
                                                            <Box
                                                                key={ item.id + i }
                                                                bgColor={ '#fff' }
                                                                borderWidth={ '1px' }
                                                                borderColor={ 'AVColor.hovercolor2' }
                                                                minWidth={ 130 }
                                                                minHeight={ 175 }
                                                                borderRadius={ 5 }
                                                                m={ 2 }
                                                                shadow={ 1 }
                                                            >
                                                                { isOwner && (
                                                                    <Pressable
                                                                        key={ item.id }
                                                                        padding={ 1.5 }
                                                                        rounded={ 'full' }
                                                                        position={ 'absolute' }
                                                                        right={ -10 }
                                                                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                        top={ -10 }
                                                                        backgroundColor={ 'AVColor.buttonBg' }
                                                                        zIndex={ 999 }
                                                                        onPress={ () => HandleRemoveDepantdant(item) }
                                                                    >
                                                                        <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                                                                    </Pressable>
                                                                ) }
                                                                <VStack alignItems={ 'center' } width={ '100%' } height={ '100%' }>
                                                                    <Pressable
                                                                        width={ '100%' }
                                                                        height={ '100%' }
                                                                        zIndex={ -1 }
                                                                        padding={ '15px' }
                                                                        key={ item.id }
                                                                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                        onPress={ () => {
                                                                            navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                                                                state: {
                                                                                    user: {
                                                                                        id: item?.id,
                                                                                        pk: item?.pk,
                                                                                        region: item?.region,
                                                                                        type: item?.type,
                                                                                        name: item?.title,
                                                                                    },
                                                                                },
                                                                            });
                                                                        } }
                                                                    >
                                                                        { item?.thumb && (
                                                                            <Box
                                                                                bgColor={ '#FF6600' }
                                                                                borderRadius={ [ 'full' ] }
                                                                                width={ '70px' }
                                                                                height={ '70px' }
                                                                                left={ '23px' }
                                                                                p={ 0 }
                                                                            >
                                                                                <Image
                                                                                    source={ {
                                                                                        uri: AV.Media.getThumb(item),
                                                                                    } }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    //top={ "2" }
                                                                                    width={ '100%' }
                                                                                    height={ [ '100%' ] }
                                                                                />
                                                                            </Box>
                                                                        ) }
                                                                        { !item?.thumb && (

                                                                            <Box
                                                                                alignItems={ 'center' }
                                                                            >
                                                                                <ProfileAvathar height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                            </Box>
                                                                        ) }
                                                                        <Text
                                                                            marginTop={ 2 }
                                                                            left={ '2px' }
                                                                            alignItems='center'
                                                                            width='120px'
                                                                            numberOfLines={ 2 }
                                                                            textAlign='center'
                                                                        >
                                                                            { item.title }
                                                                        </Text>
                                                                    </Pressable>
                                                                </VStack>
                                                            </Box>
                                                        )) }
                                                    </HStack>
                                                    { (DependsList?.length === 0 || DependsList?.length === undefined) && (
                                                        <HStack width={ '100%' }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    padding: '10px',
                                                                    marginTop: '-10px',
                                                                } }
                                                            />
                                                            { Loc.currentLang.helpText.person.dependentTab }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            ) }
                                            { isGuardianspage && (
                                                <Box
                                                    minHeight={ '340px' }
                                                    borderRadius={ 5 }
                                                    backgroundColor={ 'AVColor.white' }
                                                    shadow={ 1 }
                                                    flex={ 1 }
                                                    mb={ 3 }
                                                >
                                                    <VStack>
                                                        <HStack alignItems={ 'center' } justifyContent={ 'space-between' }>
                                                            <Text
                                                                fontWeight={ '500' }
                                                                marginLeft={ '15px' }
                                                                fontSize={ '22px' }
                                                                color={ 'AVColor.primary' }
                                                                py={ '12px' }
                                                            >
                                                                { Loc.currentLang.menu.guardians }
                                                            </Text>
                                                            <HStack alignItems={ 'center' } pr={ 2 }>
                                                                {/*<SearchIcon size='5' marginRight={ 5 } />*/ }

                                                                <HStack>
                                                                    <Tooltip
                                                                        label={ Loc.currentLang.helpText.person.guardianTab }
                                                                        w={ 200 }
                                                                        openDelay={ 500 }
                                                                    >
                                                                        <Box
                                                                            marginRight={ '10px' }
                                                                            p={ '9px' }
                                                                            display={ GuardianList?.length > 0 ? '' : 'none' }
                                                                            shadow={ 1 }
                                                                            marginTop={ '3px' }
                                                                            backgroundColor={ 'AVColor.liteBg' }
                                                                            rounded={ 'full' }
                                                                        >
                                                                            <img src={ helping } height={ '17px' } width={ '17px' } />
                                                                        </Box>
                                                                    </Tooltip>
                                                                    { isOwner && (
                                                                        <Button
                                                                            p={ '9px' }
                                                                            rounded={ 'full' }
                                                                            bgColor={ 'gray.100' }
                                                                            _hover={ { bgColor: 'gray.50' } }
                                                                            shadow={ 1 }
                                                                            //disabled={ isCreate }
                                                                            onPress={ () => handleProfileType('Guardian') }
                                                                        >
                                                                            <AddIcon size={ '20px' } color={ '#32AE78' } />
                                                                        </Button>
                                                                    ) }

                                                                </HStack>
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>

                                                    <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } marginBottom={ '10px' } space={ 3 } p={ '6px' }>
                                                        { GuardianList?.map((item : any, i) => (
                                                            <Box
                                                                key={ item.id + i }
                                                                bgColor={ '#fff' }
                                                                borderWidth={ '1px' }
                                                                borderColor={ 'AVColor.hovercolor2' }
                                                                minWidth={ 130 }
                                                                minHeight={ 175 }
                                                                borderRadius={ 5 }
                                                                marginTop={ '10px' }
                                                                marginX={ '5px' }
                                                                marginBottom={ '5px' }
                                                                py={ 3 }
                                                                shadow={ 1 }
                                                            >
                                                                { isOwner && (
                                                                    <Pressable
                                                                        key={ item.id }
                                                                        padding={ 1.5 }
                                                                        rounded={ 'full' }
                                                                        position={ 'absolute' }
                                                                        right={ -10 }
                                                                        top={ -10 }
                                                                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                                                                        backgroundColor={ 'AVColor.buttonBg' }
                                                                        onPress={ () => HandleRemoveGuardians(item) }
                                                                    >
                                                                        <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                                                                    </Pressable>
                                                                ) }
                                                                <VStack alignItems={ 'center' } padding={ 2 }>
                                                                    <Pressable
                                                                        key={ item.id }
                                                                        _hover={ { bgColor: '#EEEEEE' } }
                                                                        onPress={ () => {
                                                                            navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                                                                state: {
                                                                                    user: {
                                                                                        id: item?.id,
                                                                                        pk: item?.pk,
                                                                                        region: item?.region,
                                                                                        type: item?.type,
                                                                                        name: item?.title,
                                                                                    },
                                                                                },
                                                                            });
                                                                        } }
                                                                    >
                                                                        { item?.thumb && (
                                                                            <Box
                                                                                bgColor={ '#FF6600' }
                                                                                borderRadius={ [ 'full' ] }
                                                                                width={ '70px' }
                                                                                height={ '70px' }
                                                                                left={ '23px' }
                                                                                p={ 0 }
                                                                            >
                                                                                <Image
                                                                                    source={ {
                                                                                        uri: AV.Media.getThumb(item),
                                                                                    } }
                                                                                    borderRadius={ [ 'full' ] }
                                                                                    //top={ "2" }
                                                                                    width={ '100%' }
                                                                                    height={ [ '100%' ] }
                                                                                />
                                                                            </Box>
                                                                        ) }
                                                                        { !item?.thumb && (

                                                                            <Box
                                                                                alignItems={ 'center' }
                                                                            >
                                                                                <ProfileAvathar height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                            </Box>
                                                                        ) }
                                                                        <Text
                                                                            marginTop={ 2 }
                                                                            left={ '2px' }
                                                                            alignItems='center'
                                                                            width='120px'
                                                                            numberOfLines={ 2 }
                                                                            textAlign='center'
                                                                        >
                                                                            { item.title }
                                                                        </Text>
                                                                    </Pressable>
                                                                </VStack>
                                                            </Box>
                                                        )) }
                                                    </HStack>
                                                    { GuardianList?.length === 0 && (
                                                        <HStack width={ '100%' }>
                                                            <img
                                                                src={ inform }
                                                                style={ {
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    padding: '10px',
                                                                    marginTop: '-10px',
                                                                } }
                                                            />
                                                            { Loc.currentLang.helpText.person.guardianTab }
                                                        </HStack>
                                                    ) }
                                                </Box>
                                            ) }
                                        </>
                                    ) }
                                    {/*FilesTab*/ }
                                    { isDocumentPage && (
                                        <Createalbumpopup
                                            isDocumentPage={ isDocumentPage }
                                            isPhotoPage={ isPhotoPage }
                                            Parentdata={ userInfo }
                                            albumlist={ albumlist }
                                            isOwner={ isOwner }
                                            setalbumlist={ setalbumlist }
                                            filesList={ filesList }
                                            setfilesList={ setfilesList } filesListany={ undefined } editAccess={ undefined } />
                                    ) }
                                    {/*PhotoTab*/ }
                                    { isPhotoPage && (
                                        <Createalbumpopup
                                            isDocumentPage={ isDocumentPage }
                                            isPhotoPage={ isPhotoPage }
                                            albumlist={ albumlist }
                                            isOwner={ isOwner }
                                            Parentdata={ userInfo }
                                            setalbumlist={ setalbumlist }
                                            filesList={ filesList }
                                            setfilesList={ setfilesList } filesListany={ undefined } editAccess={ undefined } />
                                    ) }

                                </VStack>
                            </VStack>) }
                    </>
                }
            </AVBody>
            { showAddEventsModal && (
                <AVCreateEventModal Entity={ user } repeatWeek={ repeatWeek } setRepeatWeek={ setRepeatWeek } isGame={ isGame } isPractice={ isPractice } setShowAddEventsModal={ setShowAddEventsModal } typeevent={ typeevent } setlocationaddress={ setlocationaddress } locationaddress={ locationaddress } datafield={ datafield } showFields={ showFields } setShowFields={ setShowFields }
                    updateField={ updateField } setIsUpdateField={ setIsUpdateField } siteField={ siteField } setSiteField={ setSiteField } mapField={ mapField } setIsMapField={ setIsMapField } isFieldsName={ isFieldsName } setIsFieldsName={ setIsFieldsName } handleupdatelocationfield={ handleupdatelocationfield } setIsFieldName={ setIsFieldName } fieldName={ fieldName } isShowEdit={ showEdit } setIsShowField={ undefined } isShowField={ undefined } showAddEventsModal={ showAddEventsModal } isEditEntireSeries={ isEditEntireSeries } />
            ) }
            { showFileuploadModal &&
                (
                    <Box
                        position='fixed'
                        bgColor={ 'AVColor.overlay2' }
                        bottom={ 0 }
                        right={ 0 }
                        top={ 0 }
                        left={ 0 }
                        zIndex={ 99 }
                    >
                        <Modal.Content
                            bgColor={ '#fff' }
                            maxWidth={ '100%' }
                            width={ '50%' }
                            padding={ '24px' }
                            minHeight={ [ 500 ] }
                            mx={ 3 }
                            paddingTop={ 4 }
                            paddingX={ 4 }
                            left={ '22%' }
                            top={ '20%' }
                        >
                            <HStack fontSize={ '24px' } fontWeight={ 400 } marginBottom={ '24px' }>
                                { isMember ? 'Friends' : 'Guardians' }
                            </HStack>
                            <Modal.Body>
                                <VStack alignItems={ 'center' } space={ 4 } width={ 'auto' } overflowY={ 'auto' } height={ '334px' } justifyContent={ 'flex-start' }>
                                    <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } space={ 6 } zIndex={ -1 } justifyContent={ 'center' } height={ '145px' } width={ '98%' }>
                                        { contactList?.map((item) => (
                                            <>
                                                <HStack
                                                    alignItems={ 'center' }
                                                    p={ 2 }
                                                    borderStyle={ 'solid' }
                                                    borderColor={ 'rgba(0, 0, 0, 0.05)' }
                                                    borderRadius={ '5px' }
                                                    width={ '100%' }
                                                    marginTop={ '5px' }
                                                    height={ '40px' }

                                                >
                                                    <HStack width={ '85%' }>
                                                        <HStack marginRight={ '10px' } width={ '30%' }>
                                                            { !isEditPlayerDetails || menu !== item ? (
                                                                <Text
                                                                    fontSize={ '16px' }
                                                                    numberOfLines={ 1 }
                                                                    ellipsizeMode={ 'tail' }
                                                                    width={ '160px' }
                                                                    textAlign={ 'left' }
                                                                    textTransform={ 'capitalize' }
                                                                >
                                                                    { item?.name }
                                                                </Text>
                                                            ) : (
                                                                <Box width={ '80%' } marginTop={ '3px' }>
                                                                    <Input
                                                                        textAlign={ 'left' }
                                                                        fontSize={ '16px' }
                                                                        paddingLeft={ '0px' }
                                                                        paddingRight={ '0px' }
                                                                        value={ newname }
                                                                        onChangeText={ (e) => setnewname(e) }
                                                                    />
                                                                </Box>
                                                            ) }
                                                        </HStack>
                                                        <HStack width={ '60%' } marginRight={ '10px' }>
                                                            { !isEditPlayerDetails || menu !== item ? (
                                                                <Text
                                                                    fontSize={ '16px' }
                                                                    numberOfLines={ 1 }
                                                                    width={ '100%' }
                                                                    ellipsizeMode={ 'tail' }
                                                                    textAlign={ 'left' }
                                                                    textTransform={ 'capitalize' }
                                                                >
                                                                    { item?.email }
                                                                </Text>
                                                            ) : (
                                                                <Box width={ '80%' } marginTop={ '3px' }>
                                                                    <Input
                                                                        textAlign={ 'left' }
                                                                        fontSize={ '16px' }
                                                                        paddingLeft={ '0px' }
                                                                        paddingRight={ '0px' }
                                                                        value={ newmail }
                                                                        defaultValue={ menu?.email }
                                                                        onChangeText={ (e) => setnewmail(e) }
                                                                    />

                                                                </Box>
                                                            ) }
                                                        </HStack>
                                                    </HStack>
                                                    <HStack position={ 'absolute' } right={ 0 } space={ 3 }>
                                                        { (!isEditPlayerDetails || menu !== item) && (
                                                            <Button
                                                                backgroundColor={ 'white' } height={ '40px' } width={ '50px' }
                                                                isDisabled={ isLoading }
                                                                onPress={ () => {
                                                                    setmenu(item);
                                                                    setnewname(item?.name)
                                                                    setnewmail(item?.email)
                                                                    setIsEditPlayerDetails(true);
                                                                } }
                                                            >
                                                                <HStack>
                                                                    <Text
                                                                        fontSize={ '16px' }
                                                                        underline

                                                                        textAlign={ 'center' }
                                                                        color={ '#00A7BC' }
                                                                    >
                                                                        { Loc.currentLang.menu.edit }
                                                                    </Text>
                                                                </HStack>
                                                            </Button>
                                                        ) }{ ' ' }
                                                        { isEditPlayerDetails && menu === item && (
                                                            <Button
                                                                backgroundColor={ 'white' } height={ '40px' } width={ '50px' }
                                                                isDisabled={ isLoading }
                                                                onPress={ () => {
                                                                    handleUpdateStaff(item);
                                                                } }
                                                            >
                                                                <HStack>
                                                                    <Text
                                                                        fontSize={ '16px' }
                                                                        underline

                                                                        textAlign={ 'center' }
                                                                        color={ '#00A7BC' }
                                                                    >
                                                                        { Loc.currentLang.menu.update }
                                                                    </Text>
                                                                </HStack>
                                                            </Button>
                                                        ) }
                                                        <Button isDisabled={ isLoading } backgroundColor={ 'white' } height={ '40px' } width={ '50px' } onPress={ () => handleRemoveStaff(item) }>
                                                            <HStack>
                                                                <Text
                                                                    fontSize={ '16px' }
                                                                    underline
                                                                    textAlign={ 'center' }
                                                                    color={ '#DF2827' }
                                                                >
                                                                    { Loc.currentLang.menu.delete }
                                                                </Text>
                                                            </HStack>
                                                        </Button>
                                                    </HStack>
                                                </HStack>
                                                <Divider marginTop={ '5px' } />
                                            </>
                                        )) }
                                    </HStack>

                                </VStack>
                            </Modal.Body>
                            <HStack
                                width={ '100%' }
                                space={ 5 }
                                flex={ 1 }
                                justifyContent={ 'flex-end' }
                                alignItems={ 'top' }
                                pr={ 5 }
                                top={ '88%' }
                                position='absolute'
                                right='10px'
                                maxHeight={ '200px' }
                                padding={ 0 }
                                background={ 'none' }
                            >
                                <Button
                                    background={ '#EEEEEE' }
                                    borderRadius={ 15 }
                                    px={ 16 }
                                    height={ '40px' }
                                    width={ '40px' }
                                    rounded='full'
                                    onPress={ () => {
                                        setshowFileuploadModal(false);
                                    } }
                                >
                                    <Text color={ '#000000' }>{ Loc.currentLang.menu.cancel }</Text>
                                </Button>
                                <Button
                                    bgColor={ 'AVColor.primary' }
                                    height={ '40px' }
                                    isLoading={ isLoading }
                                    isLoadingText={ 'Saving..' }
                                    width={ '40px' }
                                    borderRadius={ 15 }
                                    px={ 16 }
                                    rounded='full'
                                    onPress={ handleAddContactList }
                                >
                                    <Text color={ '#ffffff' }>{ Loc.currentLang.menu.save }</Text>
                                </Button>
                            </HStack>
                        </Modal.Content>
                    </Box>
                ) }
            { !isSuspended && userInfo.status === 'Suspended' && user?.id !== userID && (
                <Box
                    position={ 'fixed' }
                    top={ 0 }
                    bottom={ 0 }
                    left={ 0 }
                    right={ 0 }
                    display={ 'flex' }
                    alignItems={ 'center' }
                    justifyContent={ 'center' }
                    backgroundColor={ 'rgba(0, 0, 0, 0.25)' }
                    zIndex={ 9999 }
                >
                    <div className={ `customModal` }>
                        <div className={ `show_unauthorized_details` }>
                            <VStack alignItems={ 'center' } width={ '100%' }>
                                <HStack
                                    position={ 'absolute' }
                                    justifyContent={ 'flex-end' }
                                    right={ '15px' }
                                    top={ '13px' }
                                    width={ '100%' }
                                >
                                    <AVCancel callback={ () => setIsSuspended(true) } color={ undefined } size={ undefined } />
                                </HStack>
                                <img src={ suspend } alt='image' height={ '220px' } width={ '240px' } />
                                <HStack paddingBottom={ '8px' }>
                                    <Text fontWeight={ 400 } fontSize={ '24px' }>
                                        { Loc.currentLang.menu.temporaryTakingaBreak }
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text>{ Loc.currentLang.menu.restrictPostTxt }</Text>
                                </HStack>
                                <HStack marginTop={ '15px' }>
                                    <Button
                                        rounded='full'
                                        backgroundColor={ '#EEEEEE' }
                                        height={ '30px' }
                                        width={ '60px' }
                                        onPress={ () => setIsSuspended(true) }
                                    >
                                        <Text fontSize={ '14px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                                            { Loc.currentLang.menu.ok }
                                        </Text>
                                    </Button>
                                </HStack>
                            </VStack>
                        </div>
                    </div>
                </Box>
            ) }
            { showAddProfileModal && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 } marginLeft={ isMobile ? '0px' : '' }>
                    <div
                        style={ {
                            position: 'fixed',
                            top: '53%',
                            left: '50%',
                            transform: 'translate(-53%, -50%)',
                            background: '#ffffff',
                            boxShadow:
                                '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                            width: isMobile ? '88%' : '68%',
                            borderRadius: '8px',
                            height: '75%',
                            overflowY: 'auto'
                        } }
                    >
                        <VStack flex={ 1 } minHeight={ isMobile ? '600px' : '550px' } maxHeight={ '625px' }>
                            {/* Header */ }
                            <Center>
                                { ' ' }
                                { isModalLoader && (
                                    <Loader
                                        status={
                                            isMember
                                                ? Loc.currentLang.menu.creatingFriends
                                                : Loc.currentLang.menu.creatingGuardians
                                        }
                                    />
                                ) }
                            </Center>
                            <HStack
                                alignItems={ 'center' }
                                justifyContent={ 'space-between' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }

                                width={ '100%' }
                                zIndex={ 99 }
                            >
                                <Text fontSize={ 24 }>
                                    { isMember ? Loc.currentLang.menu.addFriends : Loc.currentLang.menu.addGuardians }
                                </Text>
                                <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 }>
                                    <AVCancel callback={ () => setShowAddProfileModal(false) } size={ undefined } color={ undefined } />
                                </HStack>
                            </HStack>
                            <Divider zIndex={ -1 } marginY={ 3 } />
                            {/* if teams */ }
                            {
                                <>
                                    <HStack
                                        width={ '100%' }
                                        space={ 3 }
                                        px={ 2 }
                                        alignItems={ 'center' }
                                        justifyContent={ checkvalue ? 'space-between' : 'flex-start' }
                                        zIndex={ -1 }
                                        flexWrap={ 'wrap' }
                                        paddingY={ 4 }
                                    >
                                        <VStack ref={ menuRefs } marginBottom={ '10px' }>
                                            {/*<Input*/ }
                                            {/*    placeholder='Person Name*'*/ }
                                            {/*    value={ staffName }*/ }
                                            {/*    size="xl"*/ }
                                            {/*    onChangeText={ (e) => searchStaffItems(e) }*/ }
                                            {/*/>*/ }
                                            <AVInputtext
                                                type={ 'text' }
                                                label={ Loc.currentLang.menu.name }
                                                onChange={ (e) => searchStaffItems(e.target.value) }
                                                value={ staffName }
                                                height={ '15px' }
                                                width={ isMobile ? '245px' : '200px' } defaultValue={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                        </VStack>
                                        {/*{ checkvalue && (*/ }
                                        {/*    <>*/ }
                                        {/*        <Input*/ }
                                        {/*            placeholder='Email Address*'*/ }
                                        {/*            value={ staffEmailAddress }*/ }
                                        {/*            size="xl"*/ }
                                        {/*            onChange={ handleStaffEmailAddress }*/ }
                                        {/*        />*/ }
                                        {/*        <Input placeholder='Phone' size="xl" value={ staffPhone } onChange={ handleStaffPhone } />*/ }
                                        {/*    </>*/ }
                                        {/*)}*/ }
                                        { checkvalue && (
                                            <>
                                                <HStack marginBottom={ '10px' }>
                                                    <AVInputtext
                                                        type={ 'text' }
                                                        label={ Loc.currentLang.menu.emailAddress }
                                                        onChange={ handleStaffEmailAddress }
                                                        value={ staffEmailAddress }
                                                        height={ '15px' }
                                                        width={ isMobile ? '245px' : '200px' } defaultValue={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                                </HStack>
                                                <HStack marginBottom={ '10px' }>
                                                    <AVInputtext
                                                        type={ 'text' }
                                                        label={ Loc.currentLang.menu.phone }
                                                        onChange={ handleStaffPhone }
                                                        value={ staffPhone }
                                                        height={ '15px' }
                                                        width={ isMobile ? '245px' : '200px' } defaultValue={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                                </HStack>
                                            </>
                                        ) }
                                        { !checkvalue && (
                                            <Box p={ 1 } backgroundColor={ 'AVColor.hovercolor2' } borderRadius={ 'full' }>
                                                <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 } px={ 2 }>
                                                    <ProfilePic width={ 50 } height={ 50 } link={ checkvaluedata } />
                                                    <VStack>
                                                        <Text
                                                            minW={ '0' }
                                                            maxW={ '200px' }
                                                            text-overflow={ 'ellipsis' }
                                                            numberOfLines={ 1 }
                                                            fontWeight='bold'
                                                        >
                                                            { checkvaluedata.title }
                                                        </Text>
                                                        {/* <Text fontSize={ '12px' }>{ checkvaluedata.blurb }</Text>*/ }
                                                    </VStack>
                                                </HStack>
                                            </Box>
                                        ) }

                                        { showSearch && (
                                            <>
                                                <Box
                                                    ref={ menuRefs }
                                                    background={ '#ffffff' }
                                                    position={ 'absolute' }
                                                    borderRadius='md'
                                                    shadow='9'
                                                    top={ '70px' }
                                                    height={ 'auto' }
                                                    maxHeight={ '160px' }
                                                    width={ '230px' }
                                                    zIndex={ 999 }
                                                >
                                                    { guardianLocalFilteredResults?.length !== 0 && (
                                                        <VStack>
                                                            <HStack space={ 2 } bgColor={ 'AVColor.white' } zIndex={ 999 }>
                                                                <HStack>
                                                                    <Text color={ '#818181' } fontWeight={ 'bold' }>
                                                                        { ' ' }
                                                                        { Loc.currentLang.menu.suggestions }
                                                                    </Text>
                                                                    <Pressable onPress={ () => clearValue() }>
                                                                        <HStack justifyContent={ 'flex-end' } left={ '100px' }>
                                                                            <CrossIcon size='32px' color='#000000' alignSelf={ 'center' } />
                                                                        </HStack>
                                                                    </Pressable>
                                                                </HStack>
                                                            </HStack>
                                                        </VStack>
                                                    ) }
                                                    <FlatList
                                                        removeClippedSubviews={ false }
                                                        scrollEnabled={ true }
                                                        disableVirtualization={ true }
                                                        data={ guardianLocalFilteredResults }
                                                        renderItem={ RenderalertTab }
                                                        initialNumToRender={ 5 }
                                                        maxToRenderPerBatch={ 5 }
                                                        windowSize={ 3 }
                                                        onEndReachedThreshold={ 0.1 }
                                                        keyExtractor={ (item : any) => item?.id }
                                                    />
                                                    {!NoResult && (<VStack zIndex={ 999 }>
                                                        <VStack
                                                            space={ 2 }
                                                            borderRadius='md'
                                                            shadow='9'
                                                            width={ '230px' }
                                                            p={ 2.5 }
                                                            mt={ '4px' }
                                                            bgColor={ '#ffffff' }
                                                            zIndex={ 999 }
                                                            position={ 'fixed' }
                                                        >
                                                            <Pressable
                                                                onPress={ () => searchGuardian(staffName, isGuardianspage) }
                                                                zIndex={ 999 }
                                                            >
                                                                <HStack space={ 10 } zIndex={ 999 }>
                                                                    <SearchIcon size={ 'AVIconSmall' } color={ '#18B0C2' } zIndex={ 999 } />
                                                                    <Text fontWeight={ 'bold' } color={ '#18B0C2' } zIndex={ 999 }>
                                                                        { isMember
                                                                            ? Loc.currentLang.menu.searchByPerson
                                                                            : Loc.currentLang.menu.searchByGuardian }
                                                                    </Text>
                                                                </HStack>
                                                            </Pressable>
                                                        </VStack>
                                                    </VStack> ) }
                                                    { NoResult && (
                                                        <VStack zIndex={ 999 }>
                                                        <VStack
                                                            space={ 2 }
                                                            borderRadius='md'
                                                            shadow='9'
                                                            width={ '230px' }
                                                            p={ 2.5 }
                                                            mt={ '4px' }
                                                            bgColor={ '#ffffff' }
                                                            zIndex={ 999 }
                                                            position={ 'fixed' }
                                                        >
                                                            <Pressable
                                                               
                                                                zIndex={ 999 }
                                                            >
                                                                <HStack space={ 10 } zIndex={ 999 }>
                                                                    
                                                                    <Text fontWeight={ 'bold' } color={ 'black' } zIndex={ 999 }>
                                                                         {"No Records"}
                                                                    </Text>
                                                                </HStack>
                                                            </Pressable>
                                                        </VStack>
                                                        </VStack>
                                                    ) }
                                                </Box>
                                            </>
                                        ) }

                                        <VStack>
                                            <HStack space={ 1 }>
                                                <Button
                                                    bgColor={ 'AVColor.primary' }
                                                    borderRadius={ 'full' }
                                                    padding={ 2 }
                                                    marginRight={ checkvalue ? '100px' : '22px' }
                                                    isDisabled={
                                                        checkvalue
                                                            ? !staffName ||
                                                            !staffEmailAddress ||
                                                            !emailRegex.test(staffEmailAddress)
                                                            : !staffName
                                                    }
                                                    onPress={ () =>
                                                        handleAddProfile(
                                                            isMember ? 'Member' : 'Guardian'
                                                        )
                                                    }
                                                >
                                                    <AddIcon size={ '20px' } color='AVColor.white' />
                                                </Button>
                                                { !checkvalue && (
                                                    <Button
                                                        alignSelf={ isMobile ? 'center' : '' }
                                                        _hover={ { bgColor: 'AVColor.grey', borderRadius: 'sm' } }
                                                        rounded={ 'full' }
                                                        bgColor={ 'gray.100' }
                                                        borderRadius={ 'full' }
                                                        padding={ 2 }
                                                        onPress={ () => {
                                                            setcheckvalue(true);
                                                            setcheckvaluedata('');
                                                            setStaffName('')
                                                        } }
                                                    >
                                                        <CloseIcon size={ '20px' } color='black' />
                                                    </Button>
                                                ) }
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                    <HStack width='100%' height='auto' zIndex='9999'>
                                        { filteredResults.length > 0 && (
                                            <Box
                                                width={ '23%' }
                                                position='absolute'
                                                maxHeight='230px'
                                                bottom={ '-153px' }
                                                minHeight='43px'
                                                backgroundColor={ '#f2f2f2' }
                                                background={ '#ffffff' }
                                                borderColor={ 'AVColor.hovercolor2' }
                                                shadow='2'
                                                left={ 4 }
                                                borderRadius='8px'
                                                overflowY='scroll'
                                            >
                                                <Box bgColor={ [ 'AVColor.white' ] }>
                                                    <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ '200px' }>
                                                        <VStack space={ 3 }>
                                                            { filteredResults.map((item : any, i) => (
                                                                <label key={ item.id + i } onClick={ (e) => handleStaffName(item) }>
                                                                    <HStack space={ 2 } alignItems='center' px={ 3 } py={ 3 }>
                                                                        <ProfilePic width={ 50 } height={ 50 } link={ item } />
                                                                        <VStack>
                                                                            <Text fontWeight='bold'>{ item.title }</Text>
                                                                            {/*    <Text fontSize={ '12px' }>{ item.role.name }</Text>*/ }
                                                                        </VStack>
                                                                    </HStack>
                                                                </label>
                                                            )) }
                                                        </VStack>
                                                    </ScrollView>
                                                </Box>
                                            </Box>
                                        ) }
                                    </HStack>
                                    <ScrollView zIndex={ -2 }>
                                        <View
                                            flexDir={ 'row' } marginTop={ '-5px' } zIndex={ -3 } alignItems={ 'center' }
                                        >
                                            <View flex={ 1 } height={ '1px' } zIndex={ -3 } backgroundColor={ '#D3D3D3' } />
                                            <View>
                                                <Text style={ { width: 50, textAlign: 'center' } }>
                                                    { Loc.currentLang.menu.or }
                                                </Text>
                                            </View>
                                            <View flex={ 1 } height={ '1px' } zIndex={ -3 } backgroundColor={ '#D3D3D3' } />
                                        </View>
                                        <VStack
                                            borderStyle={ 'dashed' }
                                            borderColor={ 'gray.300' }
                                            borderRadius={ '5px' }
                                            borderWidth={ '1px' }
                                            padding={ '20px' }
                                            width={ '80%' }
                                            alignSelf={ 'center' }
                                            zIndex={ -3 }
                                        > <div
                                            style={ { textAlign: 'center' } }
                                            onDrop={ handleDrop }
                                            onDragOver={ handleDragOver }
                                        >
                                                <HStack justifyContent={ 'center' } space={ 2 }>
                                                    <Pressable onPress={ () => imageInputRef?.current?.click() }>
                                                        <HStack space={ 2 }>
                                                            <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.uploadFile } </Text>
                                                            <Text color={ 'black' } fontWeight={ 500 }>{ Loc.currentLang.menu.or }</Text>
                                                            <Text color={ 'black' }>{ Loc.currentLang.menu.dragNDrop }</Text>
                                                        </HStack>
                                                    </Pressable>
                                                </HStack>
                                                <Text textAlign={ 'center' } color={ 'gray.300' }>
                                                    ({ Loc.currentLang.menu.docExtensions })
                                                </Text>
                                                { error && (
                                                    <Text textAlign={ 'center' } color={ '#DF2827' }>
                                                        { ' ' }
                                                        { error }
                                                    </Text>
                                                ) }
                                            </div>
                                        </VStack>
                                        <input
                                            type='file'
                                            accept=".txt,.docx,.xlsx,.ods,.odt,.csv"
                                            multiple
                                            hidden
                                            ref={ imageInputRef }
                                            onChange={ handlefileUpload }
                                        />
                                        { (isMember) && (<HStack justifyContent={ 'center' } top={ '50px' } width={ '100%' } flex-wrap={ 'wrap' }  >
                                            <HStack width={ '100%' } >
                                                <SocialShare shareUrl={ shareUrl } />
                                            </HStack>
                                        </HStack>) }
                      <HStack width={ '95%' } textAlign={  'justify' } marginTop={ isMobile ? "15%" : isLMobile ? '15%' : '10%' }>
                                            <img
                                                src={ inform }
                                                style={ {
                                                    width: '20px',
                                                    height: '20px',
                                                    padding: '10px',
                                                    marginTop: '-10px',
                                                } }
                                            />
                                            { Loc.currentLang.helpText.addPlayer }
                                        </HStack>
                                        { isMember && (
                                            <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } zIndex={ -3 }>
                                                { showtemplist?.map((item : any, i) => (
                                                    <Box
                                                        key={ item.id + i }
                                                        bgColor={ '#fff' }
                                                        borderWidth={ '1px' }
                                                        borderColor={ '#e7ebe5' }
                                                        margin={ 4 }
                                                        overflow='hiden'
                                                        minWidth={ 130 }
                                                        minHeight={ 130 }
                                                        rounded='lg'
                                                    >

                                                        <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                                                            <Pressable
                                                                key={ item.id }
                                                                height={ '100%' }
                                                                width={ '100%' }
                                                                justifyContent={ 'center' }
                                                                _hover={ { bgColor: 'gray.100' } }
                                                                p={ 2 }
                                                                borderRadius={ 5 }
                                                                minWidth={ 120 }
                                                                onPress={ () =>
                                                                {
                                                                    setShowAddProfileModal( false );
                                                                    navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                                                        state: {
                                                                            user: {
                                                                                id: item?.id,
                                                                                pk: item?.pk,
                                                                                region: item?.region,
                                                                                type: item?.type,
                                                                                name: item?.title,
                                                                            },
                                                                        },
                                                                    });
                                                                } }
                                                            >
                                                                { item?.thumb && (
                                                                    <Box
                                                                        bgColor={ '#FF6600' }
                                                                        borderRadius={ [ 'full' ] }
                                                                        width={ '70px' }
                                                                        height={ '70px' }
                                                                        left={ '20px' }
                                                                        p={ 0 }
                                                                    >
                                                                        <Image
                                                                            source={ {
                                                                                uri: AV.Media.getThumb(item),
                                                                            } }
                                                                            borderRadius={ [ 'full' ] }
                                                                            //top={ "2" }
                                                                            width={ '100%' }
                                                                            height={ [ '100%' ] }
                                                                        />
                                                                    </Box>
                                                                ) }
                                                                { !item?.thumb && (

                                                                    <Box
                                                                        alignItems={ 'center' }
                                                                    >
                                                                        <ProfileAvathar height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                    </Box>
                                                                ) }
                                                                <Text
                                                                    marginTop={ 2 }
                                                                    left={ '2px' }
                                                                    alignItems='center'
                                                                    width='120px'
                                                                    numberOfLines={ 2 }
                                                                    textAlign='center'
                                                                >
                                                                    { item.title }
                                                                </Text>
                                                            </Pressable>
                                                        </VStack>
                                                    </Box>
                                                )) }
                                            </HStack>
                                        ) }
                                        { !isMember && (
                                            <ScrollView>
                                                <HStack
                                                    flexWrap={ 'wrap' }
                                                    alignSelf={ 'flex-start' }
                                                    zIndex={ -1 }
                                                >
                                                    { showtemplist?.map((item : any, i) => (
                                                        <Box
                                                            key={ item?.id + i }
                                                            bgColor={ '#fff' }
                                                            borderWidth={ '1px' }
                                                            borderColor={ '#e7ebe5' }
                                                            margin={ '5px' }
                                                            overflow='hiden'
                                                            minWidth={ 130 }
                                                            minHeight={ 130 }
                                                            rounded='lg'
                                                        >

                                                            <VStack alignItems={ 'center' } height={ '100%' } width={ '100%' }>
                                                                <Pressable
                                                                    key={ item?.id }
                                                                    height={ '100%' }
                                                                    width={ '100%' }
                                                                    justifyContent={ 'center' }
                                                                    _hover={ { bgColor: 'gray.100' } }
                                                                    p={ 2 }
                                                                    borderRadius={ 5 }
                                                                    minWidth={ 120 }
                                                                    onPress={ () => {
                                                                        navigate(`/profile/${ item?.id }/${ item?.region }`, {
                                                                            state: {
                                                                                user: {
                                                                                    id: item?.id,
                                                                                    pk: item?.pk,
                                                                                    region: item?.region,
                                                                                    type: item?.type,
                                                                                    name: item?.title,
                                                                                },
                                                                            },
                                                                        });
                                                                    } }
                                                                >
                                                                    { item?.thumb && (
                                                                        <Box
                                                                            bgColor={ '#FF6600' }
                                                                            borderRadius={ [ 'full' ] }
                                                                            width={ '70px' }
                                                                            height={ '70px' }
                                                                            left={ '20px' }
                                                                            p={ 0 }
                                                                        >
                                                                            <Image
                                                                                source={ {
                                                                                    uri: AV.Media.getThumb(item),
                                                                                } }
                                                                                borderRadius={ [ 'full' ] }
                                                                                //top={ "2" }
                                                                                width={ '100%' }
                                                                                height={ [ '100%' ] }
                                                                            />
                                                                        </Box>
                                                                    ) }
                                                                    { !item?.thumb && (

                                                                        <Box
                                                                            alignItems={ 'center' }
                                                                        >
                                                                            <ProfileAvathar height={ '70' } width={ '70' } color={ "#32AE78" } />
                                                                        </Box>
                                                                    ) }
                                                                    <Text
                                                                        marginTop={ 2 }
                                                                        left={ '2px' }
                                                                        alignItems='center'
                                                                        width='120px'
                                                                        numberOfLines={ 2 }
                                                                        textAlign='center'
                                                                    >
                                                                        { item?.title }
                                                                    </Text>
                                                                </Pressable>
                                                            </VStack>
                                                        </Box>
                                                    )) }
                                                </HStack>
                                            </ScrollView>
                                        ) }
                                    </ScrollView>
                                </>
                            }
                        </VStack>
                    </div>
                </Box>
            ) }
            { isConfirmDelete && <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ selectType == 'RestoreAccount' ? handleRestore : selectType == 'DeleteDepandant' ? RemoveDepandants : selectType === 'BlockUser' ? handleProfile : selectType === 'DeleteFriends' ? RemoveFriends : selectType === 'Unfollow' ? UnfollowUser : RemoveGuardinas } isLoader={ isLoader } /> }

            { showAddProfileModalGuardian && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        style={ {
                            position: 'fixed',
                            top: '53%',
                            left: '50%',
                            transform: 'translate(-53%, -50%)',
                            background: '#ffffff',
                            boxShadow:
                                '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                            width: isMobile ? '90%' : '55%',
                            borderRadius: '8px',
                            minHeight: '400px',
                        } }
                    >
                        <VStack flex={ 1 } minHeight={ '500px' } maxHeight={ '560px' } space={ 3 } width={ '100%' }>
                            {/* Header */ }
                            <Center>
                                { ' ' }
                                { isLoader && (
                                    <Loader
                                        status={
                                            isGuardian
                                                ? Loc.currentLang.menu.addingTeam
                                                : isMember
                                                    ? Loc.currentLang.menu.creatingMembers
                                                    : Loc.currentLang.menu.creatingStaff
                                        }
                                    />
                                ) }
                                { isLoading && (
                                    <Loader
                                        status={
                                            isGuardian
                                                ? Loc.currentLang.menu.removeTeam
                                                : isMember
                                                    ? Loc.currentLang.menu.removeMember
                                                    : Loc.currentLang.menu.removeStaff
                                        }
                                    />
                                ) }{ ' ' }
                            </Center>
                            <HStack
                                justifyContent={ 'space-between' }
                                borderBottomColor={ 'AVColor.grey' }
                                px={ 5 }
                                marginTop={ '-20px' }
                                zIndex={ isLoader || isLoading ? -1 : 99 }
                            >
                                <Text fontSize={ 24 }>{ Loc.currentLang.menu.addDependants }</Text>
                                <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 }>
                                    <AVCancel callback={ () => setshowAddProfileModalGuardian(false) } color={ undefined } size={ undefined } />
                                </HStack>
                            </HStack>
                            <Divider zIndex={ -1 } />
                            <HStack justifyContent={ 'center' } space={ 5 } width={ '100%' } flexWrap={ 'wrap' }>
                                <VStack marginBottom={ '15px' }>
                                    <Input
                                        placeholder={ Loc.currentLang.menu.searchByDependants }
                                        placeholderTextColor='gray.500'
                                        ml={ '2px' }
                                        rounded='full'
                                        variant='AVSearchInput'
                                        width={ '100%' }
                                        height='40px'
                                        fontSize={ '16px' }
                                        borderRadius='10'
                                        p={ 2 }
                                        backgroundColor={ '#EEEEEE' }
                                        borderColor={ 'gray.200' }
                                        InputLeftElement={
                                            <SearchIcon
                                                ml='2'
                                                size='24px'
                                                color='black'
                                                left={ '2px' }
                                                marginRight={ '10px' }
                                                alignSelf={ 'center' }
                                            />
                                        }
                                        value={ searchInput }
                                        onChangeText={ (e) => handlesearchTeams(e) }
                                    />
                                    { showSearch && (
                                        <>
                                            <Box
                                                ref={menuRefs}
                                                background={ '#ffffff' }
                                                position={ 'absolute' }
                                                borderRadius='md'
                                                shadow='9'
                                                top={ '50px' }
                                                height={ 'auto' }
                                                maxHeight={ '250px' }
                                                width={ '280px' }
                                            >
                                                { guardianLocalFilteredResults?.length !== 0 ? (
                                                    <VStack>
                                                        <HStack space={ 2 } bgColor={ 'AVColor.white' } zIndex={ 999 }>
                                                            <HStack>
                                                                <Text color={ '#818181' } fontWeight={ 'bold' }>
                                                                    { Loc.currentLang.menu.suggestions }
                                                                </Text>
                                                                <Pressable onPress={ () => clearValue() }>
                                                                    <HStack justifyContent={ 'flex-end' } left={ '150px' }>
                                                                        <CrossIcon size='32px' color='#000000' alignSelf={ 'center' } />
                                                                    </HStack>
                                                                </Pressable>
                                                            </HStack>
                                                        </HStack>
                                                    </VStack>
                                                ) : <Text> { Loc.currentLang.menu.noRecordsFound }</Text> }
                                                <FlatList
                                                    removeClippedSubviews={ false }
                                                    scrollEnabled={ true }
                                                    disableVirtualization={ true }
                                                    data={ guardianLocalFilteredResults }
                                                    renderItem={ RenderalertTab }
                                                    initialNumToRender={ 5 }
                                                    maxToRenderPerBatch={ 5 }
                                                    windowSize={ 3 }
                                                    onEndReachedThreshold={ 0.1 }
                                                    keyExtractor={ (item : any) => item?.id }
                                                />
                                                <VStack>
                                                    <VStack
                                                        space={ 2 }
                                                        borderRadius='md'
                                                        shadow='9'
                                                        width={ '275px' }
                                                        m={ 1 }
                                                        p={ 2.5 }
                                                        mt={ '4px' }
                                                        bgColor={ '#ffffff' }
                                                        zIndex={ 999 }
                                                        position={ 'fixed' }
                                                    >
                                                        <Pressable onPress={ () => searchGuardian(searchInput, isGuardianspage) }>
                                                            <HStack space={ 10 }>
                                                                <SearchIcon size={ 'AVIconSmall' } color={ '#18B0C2' } />
                                                                <Text fontWeight={ 'bold' } color={ '#18B0C2' }>
                                                                    { ' ' }
                                                                    { isGuardianspage
                                                                        ? Loc.currentLang.menu.searchByGuradians
                                                                        : Loc.currentLang.menu.searchByDependants }
                                                                </Text>
                                                            </HStack>
                                                        </Pressable>
                                                    </VStack>
                                                </VStack>
                                            </Box>
                                        </>
                                    ) }
                                </VStack>
                                { !checkvalue && (
                                    <Box borderRadius={ 'full' } marginTop={ isMobile ? '10px' : '' }>
                                        <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 }>
                                            <ProfilePic width={ 50 } height={ 50 } link={ checkvaluedata } />
                                            <VStack>
                                                <Text
                                                    fontWeight='bold'
                                                    numberOfLines={ 1 }
                                                    text-overflow={ 'ellipsis' }
                                                    width={ isMobile ? '100px' : '150px' }
                                                >
                                                    { checkvaluedata.title }
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </Box>
                                ) }
                                <VStack>
                                    <HStack space={ 5 }>
                                        { isOwner && (
                                            <Button
                                                alignSelf={ isMobile ? 'center' : '' }
                                                bgColor={ 'AVColor.primary' }
                                                borderRadius={ 'full' }
                                                isLoading={ isModalLoader }
                                                padding={ 2 }
                                                isDisabled={ checkvalue }
                                                onPress={ () => handleAddProfile(isGuardianspage ? 'Guardian' : 'Dependants') }
                                            >
                                                <AddIcon size={ '20px' } color='AVColor.white' />
                                            </Button>
                                        ) }
                                        { !checkvalue && (
                                            <Button
                                                _hover={ { bgColor: 'AVColor.grey', borderRadius: 'sm' } }
                                                rounded={ 'full' }
                                                alignSelf={ isMobile ? 'center' : '' }
                                                bgColor={ 'gray.100' }
                                                borderRadius={ 'full' }
                                                padding={ 2 }
                                                onPress={ () => { setcheckvalue(true); setcheckvaluedata(''); } }
                                            >
                                                <CloseIcon size={ '20px' } color='black' />
                                            </Button>
                                        ) }
                                    </HStack>
                                </VStack>
                            </HStack>

                            <HStack flexWrap={ 'wrap' } alignSelf={ 'flex-start' } zIndex={ -1 }>
                                <>
                                    { isGuardianspage &&
                                        showtemplist?.map((item, index) => renderGuardianItems(item, index)) }
                                    { !isGuardianspage &&
                                        showtemplist?.map((item, index) => renderGuardianItem(item, index)) }
                                </>
                            </HStack>
                        </VStack>
                    </div>
                </Box>
            ) }
        </>
    );
};

export default React.memo(Profile);
